import React, {useState} from 'react';
import {createPortal} from 'react-dom'


export default function LoveLogicCheckoutModal({directCheckoutModalFn,checkoutBtnModalLoader}) {

    const closeModalCheckoutFn = () => {
        document.querySelector('.common-modal-ll-overlay').style.display = "none";
        document.querySelector('.without-details-cart-details-fill-checkout-modal').style.display = "none";
    }

    return (
        createPortal(<>
            <div id="recipientDetails" className="recipient-details without-adding-gift-file-details popup without-details-cart-details-fill-checkout-modal" style={{display:"none"}}>
                <div className="without-adding-gift">
                    <div className="popup-close-btn" onClick={closeModalCheckoutFn}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path
                                d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
                                fill="currentColor"/>
                        </svg>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                        <path
                            d="M24.9993 8.33268C15.7946 8.33268 8.33268 15.7946 8.33268 24.9993C8.33268 34.2041 15.7946 41.666 24.9993 41.666C34.2041 41.666 41.666 34.2041 41.666 24.9993C41.666 15.7946 34.2041 8.33268 24.9993 8.33268ZM4.16602 24.9993C4.16602 13.4934 13.4934 4.16602 24.9993 4.16602C36.5053 4.16602 45.8327 13.4934 45.8327 24.9993C45.8327 36.5053 36.5053 45.8327 24.9993 45.8327C13.4934 45.8327 4.16602 36.5053 4.16602 24.9993Z"
                            fill="#0091B9"/>
                        <path
                            d="M24.9993 29.166C23.8488 29.166 22.916 28.2333 22.916 27.0827L22.916 14.5827C22.916 13.4321 23.8488 12.4993 24.9994 12.4993C26.1499 12.4993 27.0827 13.4321 27.0827 14.5827L27.0827 27.0827C27.0827 28.2333 26.1499 29.166 24.9993 29.166Z"
                            fill="#0091B9"/>
                        <path
                            d="M21.8743 34.3743C21.8743 32.6485 23.2735 31.2493 24.9993 31.2493C26.7252 31.2493 28.1243 32.6485 28.1243 34.3743C28.1243 36.1002 26.7252 37.4993 24.9993 37.4993C23.2735 37.4993 21.8743 36.1002 21.8743 34.3743Z"
                            fill="#0091B9"/>
                    </svg>
                    <h2 className="popup-title">
                        Do you want to continue without adding PO/Tax File Details? Changes cannot be made further.
                    </h2>
                    <div className="btns">
                        <span onClick={closeModalCheckoutFn} className="yes">Fill Details</span>
                        <span onClick={directCheckoutModalFn} className="no">{!checkoutBtnModalLoader && 'Checkout'}
                            {checkoutBtnModalLoader && <div style={{display : "inline-block"}} className="loader" id="loader"/>}
                        </span>
                    </div>
                </div>
            </div>
        </>, document.body)
    )
}
