import React, {useEffect, useState} from 'react';
import {createPortal} from 'react-dom'
import {findObjectWithProperties, formatMoney} from "../../helper/country";


export default function LoveLogicCartGiftAddMoreItemsModal({
                                                                cartData,
                                                               productDataArr,
                                                               addAGiftAdditionalItemAddFn,
                                                               editGiftModal
                                                          }) {
    let [productDetails, setProductDetails] = useState([]);

    useEffect(() => {
        if (cartData?.items?.length) {
            let currentvariantId = productDataArr.map(item => item.variant_id);
            const updatedDetails = cartData.items.map((itemData, index) => {
                if (!currentvariantId.includes(itemData.variant_id) && !itemData?.properties?._giftBox && !itemData?.properties?._gifting) {
                    return {
                        variantId: itemData.variant_id,
                        index: parseInt(index) + 1,
                        itemData: itemData,
                        checked: false
                    }
                }
            });
            let filteredData = updatedDetails.filter(item => item !== null && item !== undefined);
            setProductDetails(filteredData);
        } else {
            setProductDetails([])
        }

    }, [productDataArr])

    const closeAddMoreItemsModal = () => {
        document.querySelector('.ll-add-more-items-modal').style.display = "none";
    }

    const addOnCheckBoxFnExtraItem = (value, index) => {
        const updatedDetails = [...productDetails];
        updatedDetails[index] = {
            ...updatedDetails[index],
            checked: value
        };
        setProductDetails(updatedDetails)
    }

    const addOnSubBtnFn = () => {
        let findIndex = findObjectWithProperties(productDataArr);
        let properties = {};
        if (findIndex > -1) {
            properties = productDataArr[findIndex].properties;
        } else {
            properties = productDataArr[0].properties;
        }

        let data = [];
        productDetails.forEach((item) => {
            if (item.checked) {
                let blankDataObj = {...item?.itemData};
                blankDataObj.quantity = 1;
                blankDataObj.mainQuantity = item?.itemData?.quantity;
                blankDataObj.properties = {...properties, _newAdd : true};
                blankDataObj.lineItem = item?.index;
                if(blankDataObj.requires_shipping){
                    if(!blankDataObj.properties?._country){
                        blankDataObj.properties = {...blankDataObj.properties, '_country' : 'USA'};
                    }
                }
                data.push(blankDataObj);
            }
        })
        addAGiftAdditionalItemAddFn(data)
    }

    return (
        createPortal(<>
            <div className="modal add-gift-details-main ll-add-more-items-modal">
                <div className="modal-content">
                    <div className="force-overflow">

                        <div className="popup-header">
                            <h4>Add a Gift</h4>
                            <span onClick={closeAddMoreItemsModal} className="close">&times;</span>
                        </div>
                        {productDetails?.length ? productDetails?.map((products, index) => {
                            return (
                                <>
                                    <div className="table-wrapper">
                                        <div className="cart-items-main form-submit">
                                            <table className="cart-items">
                                                <tr className="cart-item">
                                                    <td className="cart-item__checkbox center">
                                                        <input
                                                            style={{width: "22px", height: "22px"}}
                                                            className="cart-item-checkbox"
                                                            type="checkbox"
                                                            checked={products?.checked || false}
                                                            onChange={(event) => {
                                                                addOnCheckBoxFnExtraItem(event.target.checked, index)
                                                            }}
                                                        />
                                                    </td>
                                                    <td className="cart-item__media">
                                                        <div className="cart-item__image-container">
                                                            <img src={products?.itemData?.image}
                                                                 className="cart-item__image"
                                                                 alt={products?.itemData?.title}
                                                                 loading="lazy" width="80" height="80"/>
                                                        </div>

                                                    </td>

                                                    <td className="cart-item__details">
                                                        <a href={products?.itemData?.url} target="_blank"
                                                           className="cart-item__details_title">{products?.itemData?.title}</a>
                                                        <p className="cart-item__details_price"
                                                           style={{margin: "6px 0"}}>{formatMoney(products?.itemData?.final_price)}</p>
                                                        {!products?.itemData?.product_has_only_default_variant ?
                                                            <p className="cart-item__details_format">{products?.itemData?.options_with_values?.[0]?.['name']}:{products?.itemData?.options_with_values?.[0]?.['value']}</p>
                                                        : ''}
                                                        <div className="hw--large-up-hide hw-mobile-show-main-div">
                                                            <div className="hw-mobile-show-div">
                                                                <span style={{marginRight: "10px"}}>Qty:</span>1
                                                            </div>

                                                            <div className="cart-item__price-wrapper">
                                                                <span
                                                                    className="cart-item-price-mob-text">Total: </span>
                                                                <span className="price price--end">
                                                                   {formatMoney(products?.itemData?.final_price)}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </td>

                                                    <td className="cart-item__quantity center small-hide">
                                                        <span style={{marginRight: "10px"}}>Qty</span>1
                                                    </td>

                                                    <td className="cart-item__totals center small-hide">
                                                        <div className="cart-item__price-wrapper">
                                                            <span className="price price--end">
                                                                {formatMoney(products?.itemData?.final_price)}
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </>
                            )
                        }) : <div style={{textAlign : "center",fontWeight : "700", paddingTop : "20%"}}>No Data Found</div>}

                        <div className="popup-footer">
                            <span onClick={() => {
                                addOnSubBtnFn()
                            }} className="yes">Add</span>
                        </div>
                    </div>
                </div>
            </div>

        </>, document.body)
    )
}
