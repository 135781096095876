import React, {useEffect, useState} from 'react';
import {createPortal} from 'react-dom'
import {findObjectWithProperties, formatMoney} from "../../helper/country";


export default function LoveLogicCartShowGiftRecipientData({
                                                               cartData,
                                                               giftRecipientDeleteBtn,
                                                               editGiftRecipientDetailsBtn
                                                           }) {
    const [showDetails, setShowDetails] = useState(false);
    const [productData, setProductData] = useState([]);
    useEffect(() => {
        if (cartData?.items?.length) {
            const hasGiftingItem = cartData.items.some(item => item?.properties?._gifting);
            setShowDetails(hasGiftingItem);
            if (hasGiftingItem) {
                let product_data = cartData.items;
                const giftedData = product_data.map((obj, index) => {
                    if (obj.properties && obj?.properties?._gifting) {
                        return {
                            ...obj, lineItem: index + 1
                        }
                    }
                });
                let filteredObjects = giftedData.filter(item => item !== null && item !== undefined);
                const groupedObjects = filteredObjects.reduce((groups, obj) => {
                    const key = `${obj.properties._name}_${obj.properties._email}`;
                    if (!groups[key]) {
                        groups[key] = [];
                    }
                    groups[key].push(obj);
                    return groups;
                }, {});
                const groupedArray = Object.keys(groupedObjects).map(key => groupedObjects[key]);
                setProductData(groupedArray)
            } else {
                setProductData([])
            }
        } else {
            setShowDetails(false)
            setProductData([])
        }
        document.addEventListener("DOMContentLoaded", function () {
            let giftedItemsGrid = document.querySelector('.gifted-items-grid');
            let tables = document.querySelectorAll('.gifted-items-grid-tr');
            if (tables.length >= 2) {
                giftedItemsGrid.classList.add('more-than-two-tables');
            }
            else{
                giftedItemsGrid.classList.add('le-than-two-tables');
            }
        });
    }, [cartData])

    return (
        createPortal(<>
                {showDetails ? <div className="gifted-items-main">
                    <div className="hw--container">
                        <div className="header">Gifted Items</div>
                        <div className="gifted-items-grid-main">
                            <table className="table">
                                <thead className="small-hide">
                                <tr>
                                    <th>PRODUCT</th>
                                    <th>QUANTITY</th>
                                    <th>TOTAL</th>
                                    <th>RECIPIENT</th>
                                    <th>ACTION</th>
                                </tr>
                                </thead>
                                <tbody className="gifted-items-grid">
                                {productData?.map((mainitemData, mainindex) => {
                                    let findIndex = findObjectWithProperties(mainitemData);
                                    let properties = {};
                                    if (findIndex > -1) {
                                        properties = mainitemData[findIndex].properties;
                                    } else {
                                        properties = mainitemData[0].properties;
                                    }
                                    return (mainitemData?.length && mainitemData?.map((itemData, index) => {
                                        let classnameMainTr = 'gifted-items-grid-tr';
                                        if(!index){
                                            classnameMainTr = 'gifted-items-grid-tr gifted-items-new-product';
                                        }
                                        return (itemData?.properties?._gifting ?
                                            <>
                                                <tr className={classnameMainTr}>
                                                    <td className="gifted-item-img-title">
                                                        <div>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25"
                                                                 viewBox="0 0 24 25"
                                                                 fill="none">
                                                                <path fillRule="evenodd" clipRule="evenodd"
                                                                      d="M3.66748 4.31853C3.66748 5.5031 4.71912 6.99676 6.97174 7.92352H3.45833C2.65417 7.92352 2 8.57769 2 9.38186V11.4652C2 12.2694 2.65417 12.9235 3.45833 12.9235H3.66669V20.6319C3.66669 21.436 4.32085 22.0902 5.12502 22.0902H11.9951L12 22.0902L12.0049 22.0902H18.875C19.6792 22.0902 20.3334 21.436 20.3334 20.6319V12.9235H20.5417C21.3458 12.9235 22 12.2694 22 11.4652V9.38186C22 8.57769 21.3458 7.92352 20.5417 7.92352H17.0292C19.2817 6.99675 20.3333 5.50312 20.3333 4.31857C20.3333 3.27357 19.5325 2.05357 17.2742 2.05273C14.4568 2.05273 12.8204 4.05085 12.0003 5.95369C11.1801 4.05109 9.54388 2.05353 6.72748 2.05353C4.46915 2.05353 3.66748 3.27353 3.66748 4.31853ZM12.625 20.8402H18.875C18.9303 20.8402 18.9833 20.8182 19.0223 20.7792C19.0614 20.7401 19.0834 20.6871 19.0834 20.6319V12.9235H12.625V20.8402ZM12.625 11.6735H20.5417C20.5969 11.6735 20.6499 11.6516 20.689 11.6125C20.7281 11.5734 20.75 11.5204 20.75 11.4652V9.38186C20.75 9.3266 20.7281 9.27361 20.689 9.23454C20.6499 9.19547 20.5969 9.17352 20.5417 9.17352H12.625V11.6735ZM11.375 9.17352V11.6735H3.45833C3.34333 11.6735 3.25 11.5802 3.25 11.4652V9.38186C3.25 9.3266 3.27195 9.27361 3.31102 9.23454C3.35009 9.19547 3.40308 9.17352 3.45833 9.17352H11.375ZM11.375 12.9235V20.8402H5.12502C5.01002 20.8402 4.91669 20.7469 4.91669 20.6319V12.9235H11.375ZM12.78 7.52524C13.1558 6.14107 14.2892 3.30357 17.2742 3.30357C18.1492 3.30357 19.0833 3.5694 19.0833 4.31773C19.0833 4.82024 18.6608 5.60774 17.475 6.3119C16.62 6.8194 15.135 7.41524 12.78 7.52524ZM6.72748 3.30353C5.85248 3.30353 4.91748 3.57019 4.91748 4.31853C4.91748 5.38269 6.77831 7.32186 11.22 7.52603C10.845 6.14103 9.71165 3.30353 6.72748 3.30353Z"
                                                                      fill="#ED1D24"/>
                                                            </svg>
                                                        </div>
                                                        <div>
                                                            <img src={itemData?.image} alt={itemData?.product_title}
                                                                 className="product-image" />
                                                        </div>
                                                        <div>
                                                            <a target="_blank" href={itemData?.url} className="cart-item__details_title">{itemData?.product_title}</a>
                                                            <p className="cart-item__details_price" style={{margin : "6px 0"}}>{formatMoney(itemData?.final_price)}</p>
                                                            {!itemData?.product_has_only_default_variant ?
                                                                <p className="cart-item__details_format"><span className="format-span">{itemData?.options_with_values?.[0]?.['name']}: </span>{itemData?.options_with_values?.[0]?.['value']}</p>
                                                            : ''}
                                                        </div>
                                                    </td>
                                                    <td className="center hw--qty"><span
                                                        className="hw--large-up-hide qty-span">Qty:</span> {itemData?.quantity}
                                                    </td>
                                                    <td><span className="hw--large-up-hide total-span">Total:</span> {formatMoney(itemData?.final_line_price)}</td>
                                                    <td>
                                                        {mainitemData.length <= 1 || index === 0 ? (
                                                            <>
                                                                <div className="recipient-info">
                                                                    <p>
                                                                        <span>{properties?._name}</span><br/>
                                                                        {(properties?._country && properties?._state && properties?._city) && (
                                                                            `${properties?._address2} ${properties?._address} ${properties?._city} ${properties?._state} ${properties?._country} ${properties?._zipcode}`
                                                                        )}
                                                                    </p>
                                                                    {properties?._email ? <>
                                                                        <p>
                                                                            <span>Email:</span>
                                                                            <span className="email">{properties?._email}</span>
                                                                            {/*<a className="email" href="#">{properties?._email}</a>*/}
                                                                        </p>
                                                                    </> : ''}
                                                                    {properties?._phone ? <>
                                                                        <p><span>Phone:</span>
                                                                            <span className="phone">{properties?._phone}</span>
                                                                            {/*<a className="phone" href="#"> {properties?._phone}</a>*/}
                                                                        </p>
                                                                    </> : ''}
                                                                    {properties?._note ? <>
                                                                        <p className="gifted-item-message"><span>Gift Message:</span>{properties?._note}</p>
                                                                        </> : ''}
                                                                </div>
                                                            </>
                                                        ) : null}
                                                    </td>
                                                    <td>
                                                        {(mainitemData.length <= 1 || index === 0) ? <>
                                                            <div className="action-buttons">
                                                                <div className="recipient-edit-remove-button">
                                                                    <div onClick={() => {
                                                                        editGiftRecipientDetailsBtn(mainitemData,mainindex)
                                                                    }}  className="recipient-edit-button">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25"
                                                                             viewBox="0 0 24 25" fill="none">
                                                                            <path
                                                                                d="M16.2929 2.79289C16.6834 2.40237 17.3166 2.40237 17.7071 2.79289L21.7071 6.79289C22.0976 7.18342 22.0976 7.81658 21.7071 8.20711L8.70711 21.2071C8.51957 21.3946 8.26522 21.5 8 21.5H4C3.44772 21.5 3 21.0523 3 20.5V16.5C3 16.2348 3.10536 15.9804 3.29289 15.7929L13.2927 5.7931L16.2929 2.79289ZM14 7.91421L5 16.9142V19.5H7.58579L16.5858 10.5L14 7.91421ZM18 9.08579L19.5858 7.5L17 4.91421L15.4142 6.5L18 9.08579Z"
                                                                                fill="currentColor"/>
                                                                        </svg>
                                                                    </div>
                                                                    <div onClick={() => {
                                                                        giftRecipientDeleteBtn(mainitemData)
                                                                    }} className="recipient-remove-button">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25"
                                                                             viewBox="0 0 24 25" fill="none">
                                                                            <path
                                                                                d="M7 4.5C7 3.39543 7.89543 2.5 9 2.5H15C16.1046 2.5 17 3.39543 17 4.5V6.5H18.9897C18.9959 6.49994 19.0021 6.49994 19.0083 6.5H21C21.5523 6.5 22 6.94772 22 7.5C22 8.05228 21.5523 8.5 21 8.5H19.9311L19.0638 20.6425C18.989 21.6891 18.1182 22.5 17.0689 22.5H6.93112C5.88184 22.5 5.01096 21.6891 4.9362 20.6425L4.06888 8.5H3C2.44772 8.5 2 8.05228 2 7.5C2 6.94772 2.44772 6.5 3 6.5H4.99174C4.99795 6.49994 5.00414 6.49994 5.01032 6.5H7V4.5ZM9 6.5H15V4.5H9V6.5ZM6.07398 8.5L6.93112 20.5H17.0689L17.926 8.5H6.07398ZM10 10.5C10.5523 10.5 11 10.9477 11 11.5V17.5C11 18.0523 10.5523 18.5 10 18.5C9.44772 18.5 9 18.0523 9 17.5V11.5C9 10.9477 9.44772 10.5 10 10.5ZM14 10.5C14.5523 10.5 15 10.9477 15 11.5V17.5C15 18.0523 14.5523 18.5 14 18.5C13.4477 18.5 13 18.0523 13 17.5V11.5C13 10.9477 13.4477 10.5 14 10.5Z"
                                                                                fill="currentColor"/>
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </> : null}

                                                    </td>
                                                </tr>
                                            </> : '')
                                    }))

                                })}
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div> : null}
            </>
            , document.querySelector(`.show-ll-gift-recipient-details`))
    )
}



