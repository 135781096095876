export const countries = [
    {
        name: 'USA',
        states: [
            'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware',
            'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky',
            'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri',
            'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York',
            'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island',
            'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington',
            'West Virginia', 'Wisconsin', 'Wyoming'
        ]
    },
    {
        name: 'Canada',
        states: [
            'Alberta', 'British Columbia', 'Manitoba', 'New Brunswick', 'Newfoundland and Labrador', 'Nova Scotia',
            'Ontario', 'Prince Edward Island', 'Quebec', 'Saskatchewan'
        ]
    }
];

const validateEmail = (email) => {
    // Regular expression for basic email validation
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
}

export const commonErrorFn = (data,requires_shipping,giftProductAvailable=false) => {
    let formError = {};
    if (!data._name) {
        formError._name = 'please enter name';
    }
    if (!data._email) {
        formError._email = 'please enter email';
    }

    if(data._email && !validateEmail(data._email)) {
        formError._email = 'please enter valid email';
    }

    /*if (!data._phone) {
        formError._phone = 'please enter phone';
    }*/
    if (!data._note) {
        formError._note = 'please enter note';
    }
    if (requires_shipping) {
        if (!data._country) {
            formError._country = 'please enter country';
        }
        if (!data._address) {
            formError._address = 'please enter a address';
        }
        /*if (!data._address2) {
            formError._address2 = 'please enter a address 2';
        }*/
        if (!data._city) {
            formError._city = 'please enter city';
        }
        if (!data._state) {
            formError._state = 'please enter state';
        }
        if (!data._zipcode) {
            formError._zipcode = 'please enter zipcode';
        }

        if(!giftProductAvailable){
            if(formError._email !== 'please enter valid email'){
                delete formError._email;
            }
            if(formError._note){
                delete formError._note;
            }
        }
    }
    return formError;
}

export const findObjectWithProperties = (data) => {
    for (let i = 0; i < data.length; i++) {
        if (data[i].requires_shipping) {
            return i;
        }
    }
    return -1;
}

export const formatMoney = (cents, format) => {
    if (typeof cents == 'string') {
        cents = cents.replace('.', '');
    }
    var value = '';
    var placeholderRegex = /{{\s*(\w+)\s*}}/;
    var formatString = (format || Shopify.money_format);
    function defaultOption(opt, def) {
        return (typeof opt == 'undefined' ? def : opt);
    }
    function formatWithDelimiters(number, precision, thousands, decimal) {
        precision = defaultOption(precision, 2);
        thousands = defaultOption(thousands, ',');
        decimal = defaultOption(decimal, '.');
        if (isNaN(number) || number == null) {
            return 0;
        }
        number = (number / 100.0).toFixed(precision);
        var parts = number.split('.'),
            dollars = parts[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1' + thousands),
            cents = parts[1] ? (decimal + parts[1]) : '';
        return dollars + cents;
    }
    switch (formatString.match(placeholderRegex)[1]) {
        case 'amount':
            value = formatWithDelimiters(cents, 2);
            break;
        case 'amount_no_decimals':
            value = formatWithDelimiters(cents, 0);
            break;
        case 'amount_with_comma_separator':
            value = formatWithDelimiters(cents, 2, '.', ',');
            break;
        case 'amount_no_decimals_with_comma_separator':
            value = formatWithDelimiters(cents, 0, '.', ',');
            break;
    }
    return formatString.replace(placeholderRegex, value);
};
