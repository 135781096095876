import React, { useState, useEffect } from 'react';


export default function LoveLogicCartPoOrder({
                                                 poOrderCheckBoxSelect,
                                                 taxExemptCheckBoxSelect,
                                                 handleFilechange,
                                                 deleteFileFn,
                                                 setTaxExemptCheckBoxSelect,
                                                 setPoOrderCheckBoxSelect,
                                                 onValidationChange
                                             }) {
    const [poErrors, setPoErrors] = useState({
        poorderNumber: false,
        schoolName: false,
        poFile: false
    });

    const [validationTriggered, setValidationTriggered] = useState(false);
    useEffect(() => {
        if (poOrderCheckBoxSelect?.checkbox) {
            if (validationTriggered) {
                const isValid = validatePoFields();
                if (onValidationChange) {
                    onValidationChange(!isValid);
                }
            } else {
                if (onValidationChange) {
                    onValidationChange(false);
                }
            }
        } else {
            setPoErrors({
                poorderNumber: false,
                schoolName: false,
                poFile: false
            });
            if (onValidationChange) {
                onValidationChange(false);
            }
        }
    }, [poOrderCheckBoxSelect?.checkbox, poOrderCheckBoxSelect?.poorderNumber, poOrderCheckBoxSelect?.schoolName, poOrderCheckBoxSelect?.poFile, poOrderCheckBoxSelect?.base64File, validationTriggered]);

    const validatePoFields = () => {
        const errors = {
            poorderNumber: false,
            schoolName: false,
            poFile: false
        };

        if (!poOrderCheckBoxSelect?.poorderNumber || poOrderCheckBoxSelect?.poorderNumber.trim() === '') {
            errors.poorderNumber = true;
        }

        if (!poOrderCheckBoxSelect?.schoolName || poOrderCheckBoxSelect?.schoolName.trim() === '') {
            errors.schoolName = true;
        }

        if (typeof poOrderCheckBoxSelect?.poFile === 'object') {
            if (!poOrderCheckBoxSelect?.poFile?.name && poOrderCheckBoxSelect?.base64File === '') {
                errors.poFile = true;
            }
        } else if (poOrderCheckBoxSelect?.poFile === '') {
            errors.poFile = true;
        }

        setPoErrors(errors);
        return !Object.values(errors).some(error => error);
    };

    const handlePoOrderNumberChange = (e) => {
        setPoOrderCheckBoxSelect({...poOrderCheckBoxSelect, poorderNumber: e.target.value});
        if (validationTriggered && poOrderCheckBoxSelect?.checkbox) {
            const newErrors = {...poErrors, poorderNumber: !e.target.value || e.target.value.trim() === ''};
            setPoErrors(newErrors);
            if (onValidationChange) {
                onValidationChange(Object.values(newErrors).some(error => error));
            }
        }
    };

    const handleSchoolNameChange = (e) => {
        setPoOrderCheckBoxSelect({...poOrderCheckBoxSelect, schoolName: e.target.value});
        if (validationTriggered && poOrderCheckBoxSelect?.checkbox) {
            const newErrors = {...poErrors, schoolName: !e.target.value || e.target.value.trim() === ''};
            setPoErrors(newErrors);
            if (onValidationChange) {
                onValidationChange(Object.values(newErrors).some(error => error));
            }
        }
    };

    const handlePoFileChange = (event) => {
        handleFilechange(event, 'po');
        if (validationTriggered && poOrderCheckBoxSelect?.checkbox) {
            const newErrors = {...poErrors, poFile: false};
            setPoErrors(newErrors);
            if (onValidationChange) {
                onValidationChange(Object.values(newErrors).some(error => error));
            }
        }
    };

    const triggerValidation = () => {
        setValidationTriggered(true);
        if (poOrderCheckBoxSelect?.checkbox) {
            const isValid = validatePoFields();
            if (onValidationChange) {
                onValidationChange(!isValid);
            }
            return isValid;
        }
        return true;
    };

    useEffect(() => {
        if (window.triggerPoValidation) {
            window.triggerPoValidation = triggerValidation;
        } else {
            window.triggerPoValidation = triggerValidation;
        }
    }, [poOrderCheckBoxSelect]);

    return (
        <>
            <div className="cart-po-order-item">
                <div className="cart-po-order-item-one">
                    <div className="checkbox-container">
                        <input className="popup-checkbox" type="checkbox" checked={poOrderCheckBoxSelect?.checkbox} value={poOrderCheckBoxSelect?.checkbox}
                               onChange={(e) => {
                                   setPoOrderCheckBoxSelect({...poOrderCheckBoxSelect, checkbox: e.target.checked})
                               }} style={{width: "22px", height: "22px"}}/>
                        <label htmlFor="po">This is a PO (School use only*)</label>
                        <div className="tooltip">
                            <div className="tooltip-svg">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none">
                                    <path
                                        d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z"
                                        fill="#CCCCCC"/>
                                    <path
                                        d="M12 10C12.5523 10 13 10.4477 13 11V17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17V11C11 10.4477 11.4477 10 12 10Z"
                                        fill="#CCCCCC"/>
                                    <path
                                        d="M13.5 7.5C13.5 8.32843 12.8284 9 12 9C11.1716 9 10.5 8.32843 10.5 7.5C10.5 6.67157 11.1716 6 12 6C12.8284 6 13.5 6.67157 13.5 7.5Z"
                                        fill="#CCCCCC"/>
                                </svg>
                            </div>
                            <span className="tooltiptext">
                                    School purchase orders must include the following company information <br/>
                                    <a href="https://cdn.shopify.com/s/files/1/0081/4386/3885/files/MindWorks_-_2024_W9.pdf?v=1734732418" target="_blank" className="tooltip-link" style={{color : "red"}}>(Download the W-9)</a> <br/>
                                    MindWorks Innovations, Inc. <br/>
                                    959 South Coast Drive, Suite 100 <br/>
                                    Costa Mesa, CA 92626 <br/>
                                    *Use 'Gift Options' if PO includes digital purchases for individual staff members.
                                </span>
                        </div>
                    </div>
                    {poOrderCheckBoxSelect?.checkbox ? <div className="po-inputs">
                        <div className="input-group">
                            <input
                                type="text"
                                placeholder="Enter PO Order Number"
                                value={poOrderCheckBoxSelect?.poorderNumber}
                                onChange={handlePoOrderNumberChange}
                                className={poErrors.poorderNumber ? "error-input" : ""}
                            />
                            {poErrors.poorderNumber && (
                                <div className="error-message">PO Order Number is required</div>
                            )}
                        </div>
                        <div className="input-group" style={{paddingTop : "15px"}}>
                            <input
                                type="text"
                                placeholder="Enter Company/School name"
                                value={poOrderCheckBoxSelect?.schoolName}
                                onChange={handleSchoolNameChange}
                                className={poErrors.schoolName ? "error-input" : ""}
                            />
                            {poErrors.schoolName && (
                                <div className="error-message">Company/School name is required</div>
                            )}
                        </div>
                        {poOrderCheckBoxSelect?.poFile === '' ? <>
                                <div className="file-input">
                                    <input
                                        type="file"
                                        id="file-input"
                                        className={`file-input__input ${poErrors.poFile ? "error-input" : ""}`}
                                        accept=".pdf"
                                        onChange={handlePoFileChange}
                                    />
                                    <label className={`file-input__label ${poErrors.poFile ? "error-label" : ""}`} htmlFor="file-input">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="18" viewBox="0 0 22 18"
                                             fill="none">
                                            <path
                                                d="M18.4621 2.57573C17.7748 1.82597 16.5337 1.77977 15.7063 2.60711L7.60633 10.7071C7.39685 10.9166 7.39685 11.1834 7.60633 11.3929C7.8158 11.6024 8.08264 11.6024 8.29211 11.3929L14.9921 4.6929C15.3826 4.30238 16.0158 4.30238 16.4063 4.6929C16.7969 5.08342 16.7969 5.71659 16.4063 6.10711L9.70633 12.8071C8.7158 13.7976 7.18264 13.7976 6.19211 12.8071C5.20159 11.8166 5.20159 10.2834 6.19211 9.2929L14.2921 1.1929C15.8596 -0.374632 18.407 -0.425638 19.9216 1.20821C21.4738 2.77662 21.5195 5.31157 19.8921 6.82133L10.4063 16.3071C8.2158 18.4976 4.78264 18.4976 2.59211 16.3071C0.401588 14.1166 0.401588 10.6834 2.59211 8.4929L10.6921 0.3929C11.0826 0.00237539 11.7158 0.00237539 12.1063 0.3929C12.4969 0.783424 12.4969 1.41659 12.1063 1.80711L4.00633 9.90711C2.59685 11.3166 2.59685 13.4834 4.00633 14.8929C5.4158 16.3024 7.58264 16.3024 8.99211 14.8929L18.4921 5.3929C18.5024 5.38266 18.5128 5.37264 18.5235 5.36285C19.2733 4.67557 19.3195 3.43446 18.4921 2.60711C18.4819 2.59687 18.4719 2.58641 18.4621 2.57573Z"
                                                fill="#555555"/>
                                        </svg>
                                        <span>Upload PO File (PDF)</span>
                                    </label>
                                    {poErrors.poFile && (
                                        <div style={{marginTop:"-5px"}} className="error-message">PO File is required</div>
                                    )}
                                </div>
                               </> :
                            <div className="file-input">
                                <input id="file-input" className="file-input__input" />
                                <label className="file-input__label" htmlFor="file-input">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="18" viewBox="0 0 22 18"
                                         fill="none">
                                        <path
                                            d="M18.4621 2.57573C17.7748 1.82597 16.5337 1.77977 15.7063 2.60711L7.60633 10.7071C7.39685 10.9166 7.39685 11.1834 7.60633 11.3929C7.8158 11.6024 8.08264 11.6024 8.29211 11.3929L14.9921 4.6929C15.3826 4.30238 16.0158 4.30238 16.4063 4.6929C16.7969 5.08342 16.7969 5.71659 16.4063 6.10711L9.70633 12.8071C8.7158 13.7976 7.18264 13.7976 6.19211 12.8071C5.20159 11.8166 5.20159 10.2834 6.19211 9.2929L14.2921 1.1929C15.8596 -0.374632 18.407 -0.425638 19.9216 1.20821C21.4738 2.77662 21.5195 5.31157 19.8921 6.82133L10.4063 16.3071C8.2158 18.4976 4.78264 18.4976 2.59211 16.3071C0.401588 14.1166 0.401588 10.6834 2.59211 8.4929L10.6921 0.3929C11.0826 0.00237539 11.7158 0.00237539 12.1063 0.3929C12.4969 0.783424 12.4969 1.41659 12.1063 1.80711L4.00633 9.90711C2.59685 11.3166 2.59685 13.4834 4.00633 14.8929C5.4158 16.3024 7.58264 16.3024 8.99211 14.8929L18.4921 5.3929C18.5024 5.38266 18.5128 5.37264 18.5235 5.36285C19.2733 4.67557 19.3195 3.43446 18.4921 2.60711C18.4819 2.59687 18.4719 2.58641 18.4621 2.57573Z"
                                            fill="#555555"/>
                                    </svg>
                                    <span className="file-name-text-eclipse"><a target="_blank"
                                                                                href={(typeof poOrderCheckBoxSelect?.poFile == 'object') ? window.URL.createObjectURL(poOrderCheckBoxSelect?.poFile) : poOrderCheckBoxSelect?.poFile}>{(typeof poOrderCheckBoxSelect?.poFile == 'object') ? poOrderCheckBoxSelect?.poFile?.name : poOrderCheckBoxSelect?.poFile}</a></span>
                                    <div className="delete-icon" style={{marginLeft: "auto"}}>
                                        <div style={{display: "flex"}} onClick={() => {
                                            deleteFileFn('po')
                                        }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"
                                                 aria-hidden="true"
                                                 focusable="false" className="icon icon-remove">
                                                <path
                                                    d="M14 3h-3.53a3.07 3.07 0 00-.6-1.65C9.44.82 8.8.5 8 .5s-1.44.32-1.87.85A3.06 3.06 0 005.53 3H2a.5.5 0 000 1h1.25v10c0 .28.22.5.5.5h8.5a.5.5 0 00.5-.5V4H14a.5.5 0 000-1zM6.91 1.98c.23-.29.58-.48 1.09-.48s.85.19 1.09.48c.2.24.3.6.36 1.02h-2.9c.05-.42.17-.78.36-1.02zm4.84 11.52h-7.5V4h7.5v9.5z"
                                                    fill="#ed1d24"/>
                                                <path
                                                    d="M6.55 5.25a.5.5 0 00-.5.5v6a.5.5 0 001 0v-6a.5.5 0 00-.5-.5zM9.45 5.25a.5.5 0 00-.5.5v6a.5.5 0 001 0v-6a.5.5 0 00-.5-.5z"
                                                    fill="#ed1d24"/>
                                            </svg>
                                        </div>
                                    </div>
                                </label>
                            </div>}

                    </div> : null}

                </div>
                <div className="cart-po-order-item-two">
                    <div className="checkbox-container">
                        <input className="popup-checkbox" type="checkbox" checked={taxExemptCheckBoxSelect?.checkbox} value={taxExemptCheckBoxSelect?.checkbox}
                               onChange={(e) => {
                                   setTaxExemptCheckBoxSelect({...taxExemptCheckBoxSelect, checkbox: e.target.checked})
                               }}
                               style={{width: "22px", height: "22px"}}/>
                        <label htmlFor="po">Tax-exempt File (Optional)</label>
                        <div className="tooltip">
                            <div className="tooltip-svg">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none">
                                    <path
                                        d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z"
                                        fill="#CCCCCC"/>
                                    <path
                                        d="M12 10C12.5523 10 13 10.4477 13 11V17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17V11C11 10.4477 11.4477 10 12 10Z"
                                        fill="#CCCCCC"/>
                                    <path
                                        d="M13.5 7.5C13.5 8.32843 12.8284 9 12 9C11.1716 9 10.5 8.32843 10.5 7.5C10.5 6.67157 11.1716 6 12 6C12.8284 6 13.5 6.67157 13.5 7.5Z"
                                        fill="#CCCCCC"/>
                                </svg>
                            </div>
                            <span className="tooltiptext">
                                    Please upload your <br/>
                                    tax-exempt file if applicable.
                                </span>
                        </div>
                    </div>
                    {taxExemptCheckBoxSelect?.checkbox && (taxExemptCheckBoxSelect?.taxExemptFile === '' ? <>
                            <div className="file-input">
                                <input type="file" id="file-input-2" className="file-input__input" accept=".pdf"
                                       onChange={(event) => {
                                           handleFilechange(event, 'tax')
                                       }}/>
                                <label className="file-input__label" htmlFor="file-input-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="18" viewBox="0 0 22 18"
                                         fill="none">
                                        <path
                                            d="M18.4621 2.57573C17.7748 1.82597 16.5337 1.77977 15.7063 2.60711L7.60633 10.7071C7.39685 10.9166 7.39685 11.1834 7.60633 11.3929C7.8158 11.6024 8.08264 11.6024 8.29211 11.3929L14.9921 4.6929C15.3826 4.30238 16.0158 4.30238 16.4063 4.6929C16.7969 5.08342 16.7969 5.71659 16.4063 6.10711L9.70633 12.8071C8.7158 13.7976 7.18264 13.7976 6.19211 12.8071C5.20159 11.8166 5.20159 10.2834 6.19211 9.2929L14.2921 1.1929C15.8596 -0.374632 18.407 -0.425638 19.9216 1.20821C21.4738 2.77662 21.5195 5.31157 19.8921 6.82133L10.4063 16.3071C8.2158 18.4976 4.78264 18.4976 2.59211 16.3071C0.401588 14.1166 0.401588 10.6834 2.59211 8.4929L10.6921 0.3929C11.0826 0.00237539 11.7158 0.00237539 12.1063 0.3929C12.4969 0.783424 12.4969 1.41659 12.1063 1.80711L4.00633 9.90711C2.59685 11.3166 2.59685 13.4834 4.00633 14.8929C5.4158 16.3024 7.58264 16.3024 8.99211 14.8929L18.4921 5.3929C18.5024 5.38266 18.5128 5.37264 18.5235 5.36285C19.2733 4.67557 19.3195 3.43446 18.4921 2.60711C18.4819 2.59687 18.4719 2.58641 18.4621 2.57573Z"
                                            fill="#555555"/>
                                    </svg>
                                    <span>Upload Tax-exempt File (PDF)</span></label>
                            </div>
                        </> :
                        <div className="file-input">
                            <input id="file-input-5" className="file-input__input"/>
                            <label className="file-input__label" htmlFor="file-input-5">
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="18" viewBox="0 0 22 18"
                                     fill="none">
                                    <path
                                        d="M18.4621 2.57573C17.7748 1.82597 16.5337 1.77977 15.7063 2.60711L7.60633 10.7071C7.39685 10.9166 7.39685 11.1834 7.60633 11.3929C7.8158 11.6024 8.08264 11.6024 8.29211 11.3929L14.9921 4.6929C15.3826 4.30238 16.0158 4.30238 16.4063 4.6929C16.7969 5.08342 16.7969 5.71659 16.4063 6.10711L9.70633 12.8071C8.7158 13.7976 7.18264 13.7976 6.19211 12.8071C5.20159 11.8166 5.20159 10.2834 6.19211 9.2929L14.2921 1.1929C15.8596 -0.374632 18.407 -0.425638 19.9216 1.20821C21.4738 2.77662 21.5195 5.31157 19.8921 6.82133L10.4063 16.3071C8.2158 18.4976 4.78264 18.4976 2.59211 16.3071C0.401588 14.1166 0.401588 10.6834 2.59211 8.4929L10.6921 0.3929C11.0826 0.00237539 11.7158 0.00237539 12.1063 0.3929C12.4969 0.783424 12.4969 1.41659 12.1063 1.80711L4.00633 9.90711C2.59685 11.3166 2.59685 13.4834 4.00633 14.8929C5.4158 16.3024 7.58264 16.3024 8.99211 14.8929L18.4921 5.3929C18.5024 5.38266 18.5128 5.37264 18.5235 5.36285C19.2733 4.67557 19.3195 3.43446 18.4921 2.60711C18.4819 2.59687 18.4719 2.58641 18.4621 2.57573Z"
                                        fill="#555555"/>
                                </svg>
                                <span className="file-name-text-eclipse"><a target="_blank"
                                                                            href={(typeof taxExemptCheckBoxSelect?.taxExemptFile == 'object') ? window.URL.createObjectURL(taxExemptCheckBoxSelect?.taxExemptFile) : taxExemptCheckBoxSelect?.taxExemptFile}>{(typeof taxExemptCheckBoxSelect?.taxExemptFile == 'object') ? taxExemptCheckBoxSelect?.taxExemptFile?.name : taxExemptCheckBoxSelect?.taxExemptFile}</a></span>
                                <div className="delete-icon" style={{marginLeft: "auto"}}>
                                    <div style={{display: "flex"}} onClick={() => {
                                        deleteFileFn('tax')
                                    }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"
                                             aria-hidden="true"
                                             focusable="false" className="icon icon-remove">
                                            <path
                                                d="M14 3h-3.53a3.07 3.07 0 00-.6-1.65C9.44.82 8.8.5 8 .5s-1.44.32-1.87.85A3.06 3.06 0 005.53 3H2a.5.5 0 000 1h1.25v10c0 .28.22.5.5.5h8.5a.5.5 0 00.5-.5V4H14a.5.5 0 000-1zM6.91 1.98c.23-.29.58-.48 1.09-.48s.85.19 1.09.48c.2.24.3.6.36 1.02h-2.9c.05-.42.17-.78.36-1.02zm4.84 11.52h-7.5V4h7.5v9.5z"
                                                fill="#ed1d24"/>
                                            <path
                                                d="M6.55 5.25a.5.5 0 00-.5.5v6a.5.5 0 001 0v-6a.5.5 0 00-.5-.5zM9.45 5.25a.5.5 0 00-.5.5v6a.5.5 0 001 0v-6a.5.5 0 00-.5-.5z"
                                                fill="#ed1d24"/>
                                        </svg>
                                    </div>
                                </div>
                            </label>
                        </div>)
                    }
                </div>
            </div>
        </>
    )
}
