import React, {useEffect, useState} from 'react';
import {createPortal} from 'react-dom'
import {commonErrorFn, countries, formatMoney} from "../../helper/country";
import downArrow from "../../assets/media/DownArrow.gif"


export default function LoveLogicCartGiftModal({cartData, giftModalCloseFn, saveAllRecipientData,genrealGiftBoxLoader,genrealGiftBoxCheckoutLoader}) {

    const [giftRecipientDataDetails, setGiftRecipientDataDetails] = useState([]);

    useEffect(() => {
        if (cartData?.items?.length) {
            const updatedDetails = cartData.items.map((itemData, index) => {
                if (!itemData?.properties?._giftBox && !itemData?.properties?._gifting) {
                    return {
                        variantId: itemData.variant_id,
                        index: parseInt(index) + 1,
                        itemData: itemData,
                    }
                }
            });
            let filteredData = updatedDetails.filter(item => item !== null && item !== undefined);
            setGiftRecipientDataDetails(filteredData);
        } else {
            setGiftRecipientDataDetails([]);
        }
    }, [cartData]);

    const updateFormValue = (field, value, index, propertiesIndex) => {
        const updatedDetails = [...giftRecipientDataDetails];
        updatedDetails[index]['properties'][propertiesIndex] = {
            ...updatedDetails[index]['properties'][propertiesIndex],
            [field]: value
        };

        setGiftRecipientDataDetails(updatedDetails);
    }

    const addOnCheckBoxFn = (value, index) => {
        const updatedDetails = [...giftRecipientDataDetails];
        updatedDetails[index] = {
            ...updatedDetails[index],
            checkbox: value
        };
        if (!updatedDetails[index]?.properties?.length) {
            let propertiesData = [];
            let properties = {
                _gifting: false,
                _name: '',
                _email: '',
                _phone: '',
                _note: '',
                _quantity: '1'
            }
            if (updatedDetails[index]?.itemData?.requires_shipping) {
                properties._country = 'USA'
                properties._address = ''
                properties._address2 = ''
                properties._city = ''
                properties._state = ''
                properties._zipcode = ''
            }
            propertiesData.push(properties)
            updatedDetails[index] = {
                ...updatedDetails[index],
                properties: propertiesData
            };
        }
        setGiftRecipientDataDetails(updatedDetails);
    }


    const saveRecipientData = (index, propertiesIndex) => {
        let data = giftRecipientDataDetails[index]['properties'][propertiesIndex];
        let formError = commonErrorFn(data, giftRecipientDataDetails[index]?.itemData?.requires_shipping);
        if (!Object.keys(formError).length) {
            const updatedDetails = [...giftRecipientDataDetails];
            data._gifting = true;
            updatedDetails[index]['properties'][propertiesIndex] = data
            delete updatedDetails[index]['errors'];
            setGiftRecipientDataDetails(updatedDetails);
        } else {
            const updatedDetails = [...giftRecipientDataDetails];
            updatedDetails[index] = {
                ...updatedDetails[index],
                errors: formError
            };
            setGiftRecipientDataDetails(updatedDetails);
        }
    }

    const deleteRecipientData = (propertiesIndex, mainIndex) => {
        let existingData = giftRecipientDataDetails[mainIndex];
        let removeFromProperties = existingData?.properties;
        removeFromProperties.splice(propertiesIndex, 1);
        if (!removeFromProperties.length) {
            delete existingData.properties;
            existingData.checkbox = false;
        } else {
            existingData.properties = removeFromProperties;
        }
        const updatedDetails = [...giftRecipientDataDetails];
        updatedDetails[mainIndex] = existingData
        setGiftRecipientDataDetails(updatedDetails);
    }


    // console.log(giftRecipientDataDetails, 'giftRecipientDataDetails')

    const editRecipientData = (propertiesIndex, index) => {
        let data = giftRecipientDataDetails[index];
        delete data['errors'];
        data.properties = data.properties.map(obj => {
            obj._gifting = true;
            return obj;
        }).filter(item => {
            if(item._address !== '' && item._city !== '' && item._country !== ''){
                return item._name !== '';
            }else{
                return item._name !== '' && item._email !== '';
            }

        });
        data.properties[propertiesIndex]._gifting = false;
        data.properties[propertiesIndex]._edit = true;
        const updatedDetails = [...giftRecipientDataDetails];
        updatedDetails[index] = data;
        setGiftRecipientDataDetails(updatedDetails);
    }

    const addAnotherRecipient = (productData, index) => {
        const updatedDetails = [...giftRecipientDataDetails];
        let properties = {
            _gifting: false,
            _name: '',
            _email: '',
            _phone: '',
            _note: '',
            _quantity: '1'
        }
        if (updatedDetails[index]?.itemData?.requires_shipping) {
            properties._country = 'USA'
            properties._address = ''
            properties._address2 = ''
            properties._city = ''
            properties._state = ''
            properties._zipcode = ''
        }

        updatedDetails[index].properties = updatedDetails[index].properties.map(obj => {
            obj._gifting = true;
            return obj;
        }).filter(item => {
            if (item._address !== '' && item._city !== '' && item._country !== '') {
                return item._name !== '';
            } else {
                return item._name !== '' && item._email !== '';
            }
        });

        updatedDetails[index].properties.push(properties);
        setGiftRecipientDataDetails(updatedDetails);
    }

    const saveAllRecipientDetails = (checkout) => {
        let gift_recipient_data = giftRecipientDataDetails;

        if (gift_recipient_data?.length) {
            let hasErrors = false;

            gift_recipient_data.forEach((item) => {
                if (item?.checkbox) {
                    let form_errors = {};

                    if (item.properties?.length) {
                        item.properties.forEach(prop => {
                            if (!prop._gifting) {
                                let formError = commonErrorFn(prop, item.itemData?.requires_shipping);
                                if (Object.keys(formError).length > 0) {
                                    form_errors = formError;
                                    hasErrors = true;
                                }
                            }
                        });
                    }

                    if (hasErrors) {
                        item.errors = form_errors;
                    }
                }
            });

            if (hasErrors) {
                setGiftRecipientDataDetails([...gift_recipient_data]);
            } else {
                saveAllRecipientData(gift_recipient_data, checkout)
            }
        } else {
            saveAllRecipientData(gift_recipient_data, checkout)
        }

    }

    const productQuantityChangeFn = (productData, value,index, propertiesIndex) => {
        let lessProductQuantity = productData?.properties?.reduce((acc, obj) => {
            if (obj._gifting) {
                let quantity = typeof obj._quantity === 'string' ? parseInt(obj._quantity) : obj._quantity;
                return acc + quantity;
            } else {
                return acc; // Ensure to return acc if obj._gifting is false or undefined
            }
        }, 0);
        let totalProductQty = productData?.itemData?.quantity;
        if (lessProductQuantity) {
            totalProductQty = productData?.itemData?.quantity - lessProductQuantity;
        }
        if (value < 1) {
            value = 1;
        } else if (value > totalProductQty) {
            value = totalProductQty;
        }
        updateFormValue('_quantity', value, index, propertiesIndex)
    }

    const askSaveDataModal = () => {
        document.querySelector('.ask-save-details-general-modal').style.display = "block";
    }
    const askSaveDataModalClose = () => {
        document.querySelector('.ask-save-details-general-modal').style.display = "none";
    }


    return (
        createPortal(<>
                <div className="modal ll-cart-gift-modal">
                    <div className="modal-content">
                        <div className="force-overflow">

                            <div className="popup-header">
                                <h4>Select A Gift & Add Recipient Details</h4>
                                <span className="close" onClick={() => {
                                    askSaveDataModal()
                                }}>&times;</span>
                            </div>
                            {(genrealGiftBoxLoader || genrealGiftBoxCheckoutLoader) ? <div className="genereal-gift-box-loader" /> : ''}
                            <div style={{opacity: (genrealGiftBoxLoader || genrealGiftBoxCheckoutLoader) && '0.2'}} className="ll-gift-main-body">
                                {giftRecipientDataDetails?.length && giftRecipientDataDetails?.map((productData, index) => {
                                let quantity = productData?.properties?.reduce((acc, obj) => {
                                    let quantity = typeof obj._quantity === 'string' ? parseInt(obj._quantity) : obj._quantity;
                                    return acc + quantity;
                                }, 0);
                                if (!productData?.itemData?.properties?._giftBox) {
                                    return (
                                        <>
                                            <div className="table-wrapper">
                                                <div className="cart-items-main">
                                                    <table className="cart-items">
                                                        <tr className="cart-item" style={{position : "relative"}}>
                                                            {index === 0 && <img className="arrow-for-desktop small-hide" style={{position:"absolute", left : "-7px", top:0}} src={downArrow} alt="this slowpoke moves"  width={50} height={50} />}
                                                            <td className={`cart-item__checkbox center ${index === 0 ? 'mobile-arrow-checkbox' : ''}`} >
                                                                {index === 0 && <img className="arrow-for-mobile hw--large-up-hide" style={{transform : "rotate(90deg)"}} src={downArrow} alt="this slowpoke moves"  width={50} height={50} />}
                                                                <input checked={productData?.checkbox || false}
                                                                       onChange={(event) => {
                                                                           addOnCheckBoxFn(event.target.checked, index)
                                                                       }} className="cart-item-checkbox" type="checkbox"
                                                                       name="checkbox"
                                                                       style={{width: "22px", height: "22px"}}/>
                                                            </td>
                                                            <td className="cart-item__media">
                                                                <div className="cart-item__image-container">
                                                                    <img src={productData?.itemData?.image}
                                                                         className="cart-item__image"
                                                                         alt={productData?.itemData?.title} loading="lazy"
                                                                         width="80" height="80"/>
                                                                </div>

                                                            </td>

                                                            <td className="cart-item__details">
                                                                <a href={productData?.itemData?.url} target='_blank'
                                                                   className="cart-item__details_title">{productData?.itemData?.product_title}</a>
                                                                <p className="cart-item__details_price"
                                                                   style={{margin: "6px 0"}}>{formatMoney(productData?.itemData?.final_price)}</p>
                                                                {!productData?.itemData?.product_has_only_default_variant ?
                                                                    <p className="cart-item__details_format">{productData?.itemData?.options_with_values?.[0]?.['name']}:{productData?.itemData?.options_with_values?.[0]?.['value']}</p>
                                                                : ''}
                                                                <div className="hw--large-up-hide hw-mobile-show-main-div">
                                                                    <div className="hw-mobile-show-div">
                                                                        <span
                                                                            style={{marginRight: "10px"}}>Qty:</span>{productData?.itemData?.quantity}
                                                                    </div>

                                                                    <div className="cart-item__price-wrapper">
                                                                        <span
                                                                            className="cart-item-price-mob-text">Total: </span>
                                                                        <span
                                                                            className="price price--end">{formatMoney(productData?.itemData?.final_line_price)}</span>
                                                                    </div>
                                                                </div>
                                                            </td>

                                                            <td className="cart-item__quantity center small-hide">
                                                                <span
                                                                    style={{marginRight: "10px"}}>Qty</span>{productData?.itemData?.quantity}
                                                            </td>

                                                            <td className="cart-item__totals center small-hide">
                                                                <div className="cart-item__price-wrapper">
                                                                    <span className="price price--end">
                                                                      {formatMoney(productData?.itemData?.final_line_price)}
                                                                    </span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </table>

                                                    {productData?.checkbox && <>
                                                        <div className="saved-recipients-main">
                                                            {productData?.properties?.some(item => item?._gifting) &&
                                                            <div className="saved-recipients-header">
                                                                <p className="saved-recipients-heading">Saved Recipients</p>
                                                                {quantity < productData?.itemData?.quantity &&
                                                                <button onClick={() => {
                                                                    addAnotherRecipient(productData, index)
                                                                }} className="gift-form-remove-btn small-hide"
                                                                        type="submit" id="remove-button">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                         height="16" viewBox="0 0 16 16" fill="none">
                                                                        <path
                                                                            d="M8 0C8.55228 0 9 0.447715 9 1V7H15C15.5523 7 16 7.44772 16 8C16 8.55229 15.5523 9 15 9H9V15C9 15.5523 8.55228 16 8 16C7.44772 16 7 15.5523 7 15V9H1C0.447715 9 0 8.55229 0 8C0 7.44772 0.447715 7 1 7H7V1C7 0.447715 7.44772 0 8 0Z"
                                                                            fill="currentColor"/>
                                                                    </svg>
                                                                    Add Another Recipient
                                                                </button>
                                                                }

                                                            </div>
                                                            }
                                                        </div>
                                                        {productData?.properties?.some(item => item?._gifting) &&
                                                        quantity < productData?.itemData?.quantity &&
                                                        <button onClick={() => {
                                                            addAnotherRecipient(productData, index)
                                                        }} className="gift-form-remove-btn hw--large-up-hide"
                                                                type="submit" id="remove-button">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                                 viewBox="0 0 16 16" fill="none">
                                                                <path
                                                                    d="M8 0C8.55228 0 9 0.447715 9 1V7H15C15.5523 7 16 7.44772 16 8C16 8.55229 15.5523 9 15 9H9V15C9 15.5523 8.55228 16 8 16C7.44772 16 7 15.5523 7 15V9H1C0.447715 9 0 8.55229 0 8C0 7.44772 0.447715 7 1 7H7V1C7 0.447715 7.44772 0 8 0Z"
                                                                    fill="currentColor"/>
                                                            </svg>
                                                            Add Another Recipient
                                                        </button>
                                                        }

                                                        {productData?.properties?.map((propertiesData, propertiesIndex) => {
                                                            return (
                                                                propertiesData?._gifting ?
                                                                    <div className="saved-recipients" style={{marginBottom:"10px"}}>
                                                                        <div className="saved-recipient">
                                                                            <div className="recipient-address">
                                                                                <p>
                                                                                    <span className="saved-recipient-info">{propertiesData?._name}</span><br/>
                                                                                    {productData?.itemData?.requires_shipping &&
                                                                                    `${propertiesData?._address2} ${propertiesData?._address} ${propertiesData?._city} ${propertiesData?._state} ${propertiesData?._country} ${propertiesData?._zipcode}`
                                                                                    }
                                                                                </p>
                                                                            </div>
                                                                            <div className="recipient-email-phone">
                                                                                {propertiesData?._email ? <>
                                                                                    <p>
                                                                                        <span className="saved-recipient-info">Email:</span>
                                                                                        <a className="email">{propertiesData?._email}</a>
                                                                                    </p>
                                                                                </> : ''}
                                                                                {propertiesData?._phone ? <>
                                                                                    <p>
                                                                                        <span className="saved-recipient-info">Phone:</span>
                                                                                        <a className="phone">{propertiesData?._phone}</a>
                                                                                    </p>
                                                                                </> : ''}
                                                                            </div>
                                                                            {propertiesData?._note ? <>
                                                                                <div className="recipient-gift-message-main">
                                                                                    <span className="saved-recipient-info">Gift Message:
                                                                                          <p className="recipient-gift-message">
                                                                                            {propertiesData?._note}
                                                                                          </p>
                                                                                    </span>
                                                                                </div>
                                                                            </> : ''}
                                                                            <div className="recipient-edit-remove-button">
                                                                                <div onClick={() => {
                                                                                    editRecipientData(propertiesIndex, index);
                                                                                }} className="recipient-edit-button">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                                         width="24" height="25"
                                                                                         viewBox="0 0 24 25"
                                                                                         fill="none">
                                                                                        <path
                                                                                            d="M16.2929 2.79289C16.6834 2.40237 17.3166 2.40237 17.7071 2.79289L21.7071 6.79289C22.0976 7.18342 22.0976 7.81658 21.7071 8.20711L8.70711 21.2071C8.51957 21.3946 8.26522 21.5 8 21.5H4C3.44772 21.5 3 21.0523 3 20.5V16.5C3 16.2348 3.10536 15.9804 3.29289 15.7929L13.2927 5.7931L16.2929 2.79289ZM14 7.91421L5 16.9142V19.5H7.58579L16.5858 10.5L14 7.91421ZM18 9.08579L19.5858 7.5L17 4.91421L15.4142 6.5L18 9.08579Z"
                                                                                            fill="currentColor"/>
                                                                                    </svg>
                                                                                </div>
                                                                                <div onClick={() => {
                                                                                    deleteRecipientData(propertiesIndex, index);
                                                                                }} className="recipient-remove-button">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                                         width="24" height="25"
                                                                                         viewBox="0 0 24 25"
                                                                                         fill="none">
                                                                                        <path
                                                                                            d="M7 4.5C7 3.39543 7.89543 2.5 9 2.5H15C16.1046 2.5 17 3.39543 17 4.5V6.5H18.9897C18.9959 6.49994 19.0021 6.49994 19.0083 6.5H21C21.5523 6.5 22 6.94772 22 7.5C22 8.05228 21.5523 8.5 21 8.5H19.9311L19.0638 20.6425C18.989 21.6891 18.1182 22.5 17.0689 22.5H6.93112C5.88184 22.5 5.01096 21.6891 4.9362 20.6425L4.06888 8.5H3C2.44772 8.5 2 8.05228 2 7.5C2 6.94772 2.44772 6.5 3 6.5H4.99174C4.99795 6.49994 5.00414 6.49994 5.01032 6.5H7V4.5ZM9 6.5H15V4.5H9V6.5ZM6.07398 8.5L6.93112 20.5H17.0689L17.926 8.5H6.07398ZM10 10.5C10.5523 10.5 11 10.9477 11 11.5V17.5C11 18.0523 10.5523 18.5 10 18.5C9.44772 18.5 9 18.0523 9 17.5V11.5C9 10.9477 9.44772 10.5 10 10.5ZM14 10.5C14.5523 10.5 15 10.9477 15 11.5V17.5C15 18.0523 14.5523 18.5 14 18.5C13.4477 18.5 13 18.0523 13 17.5V11.5C13 10.9477 13.4477 10.5 14 10.5Z"
                                                                                            fill="currentColor"/>
                                                                                    </svg>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    : <div className="gift-form-main">
                                                                        <div id="gift-form">
                                                                            <h2 className="gift-form-heading"
                                                                                style={{marginTop: "0"}}>{propertiesData?._edit ? 'Edit' : 'Add'} Gift
                                                                                Recipient Details</h2>
                                                                            <div className="gift-form-one">
                                                                                <div className="recipient-info">
                                                                                    <input
                                                                                        style={{border: productData?.errors?._name && '1px solid red'}}
                                                                                        value={propertiesData?._name || ''}
                                                                                        onChange={(event) => {
                                                                                            updateFormValue('_name', event.target.value, index, propertiesIndex)
                                                                                        }}
                                                                                        type="text"
                                                                                        placeholder="Name"
                                                                                    />
                                                                                    <input
                                                                                        style={{border: productData?.errors?._email && '1px solid red'}}
                                                                                        value={propertiesData?._email || ''}
                                                                                        onChange={(event) => {
                                                                                            updateFormValue('_email', event.target.value, index, propertiesIndex)
                                                                                        }}
                                                                                        type="email"
                                                                                        placeholder="Email"
                                                                                    />
                                                                                    <input
                                                                                        style={{border: productData?.errors?._phone && '1px solid red'}}
                                                                                        value={propertiesData?._phone || ''}
                                                                                        onChange={(event) => {
                                                                                            updateFormValue('_phone', event.target.value, index, propertiesIndex)
                                                                                        }}
                                                                                        type="tel"
                                                                                        placeholder="Phone"/>
                                                                                </div>

                                                                                <div className="gift-info">
                                                                                    <textarea
                                                                                        style={{border: productData?.errors?._note && '1px solid red'}}
                                                                                        value={propertiesData?._note || ''}
                                                                                        onChange={(event) => {
                                                                                                  updateFormValue('_note', event.target.value, index, propertiesIndex)
                                                                                        }}
                                                                                        placeholder="Add your message here"/>
                                                                                </div>
                                                                                <div className="quantity-main">
                                                                                    <p className="quantity-text">Gift
                                                                                        Quantity</p>
                                                                                    <div className="quantity">
                                                                                        <button onClick={() => {
                                                                                            let value = (parseInt(propertiesData?._quantity) - 1)
                                                                                            productQuantityChangeFn(productData, value, index, propertiesIndex)
                                                                                        }} className="minus"
                                                                                                aria-label="Decrease">-</button>
                                                                                        <input
                                                                                            min="1"
                                                                                            max={productData?.itemData?.quantity}
                                                                                            type="number"
                                                                                            onChange={(event) => {
                                                                                                productQuantityChangeFn(productData, event.target.value, index, propertiesIndex)
                                                                                            }}
                                                                                            value={propertiesData?._quantity || '1'}
                                                                                               />
                                                                                        <button onClick={() => {
                                                                                            let value = (parseInt(propertiesData?._quantity) + 1)
                                                                                            productQuantityChangeFn(productData, value, index, propertiesIndex)
                                                                                        }} className="plus" aria-label="Increase">+</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {productData?.itemData?.requires_shipping && <>
                                                                                <h2 className="gift-form-heading">Delivery Address</h2>
                                                                                <div className="address">
                                                                                    <input
                                                                                        style={{border: productData?.errors?._address && '1px solid red'}}
                                                                                        value={propertiesData?._address || ''}
                                                                                        onChange={(event) => {
                                                                                            updateFormValue('_address', event.target.value, index, propertiesIndex)
                                                                                        }}
                                                                                        className="address-input"
                                                                                        type="text"
                                                                                        placeholder="Address"
                                                                                    />
                                                                                    <input
                                                                                        style={{border: productData?.errors?._address2 && '1px solid red'}}
                                                                                        value={propertiesData?._address2 || ''}
                                                                                        onChange={(event) => {
                                                                                            updateFormValue('_address2', event.target.value, index, propertiesIndex)
                                                                                        }}
                                                                                        className="apartment"
                                                                                        type="text"
                                                                                        placeholder="Address 2"/>
                                                                                    <input
                                                                                        style={{border: productData?.errors?._city && '1px solid red'}}
                                                                                        value={propertiesData?._city || ''}
                                                                                        type="text"
                                                                                        onChange={(event) => {
                                                                                            updateFormValue('_city', event.target.value, index, propertiesIndex)
                                                                                        }}
                                                                                        className="city"
                                                                                        placeholder="City"/>
                                                                                    <select className="state"
                                                                                            style={{border: productData?.errors?._state && '1px solid red'}}
                                                                                            value={propertiesData?._state || ''}
                                                                                            onChange={(event) => {
                                                                                                updateFormValue('_state', event.target.value, index, propertiesIndex)
                                                                                            }}>
                                                                                        <option value="">Select State</option>
                                                                                        {countries.find(country => country.name === propertiesData?._country)?.states.map((state, index) => (
                                                                                            <option key={index}
                                                                                                    value={state}>{state}</option>
                                                                                        ))}
                                                                                    </select>
                                                                                    <input
                                                                                        style={{border: productData?.errors?._zipcode && '1px solid red'}}
                                                                                        value={propertiesData?._zipcode || ''}
                                                                                        type="text"
                                                                                        onChange={(event) => {
                                                                                            updateFormValue('_zipcode', event.target.value, index, propertiesIndex)
                                                                                        }}
                                                                                        className="zip"
                                                                                        placeholder="Zip Code"/>
                                                                                    <select className="country"
                                                                                            style={{border: productData?.errors?._country && '1px solid red'}}
                                                                                            value={propertiesData?._country || ''}
                                                                                            onChange={(event) => {
                                                                                                updateFormValue('_country', event.target.value, index, propertiesIndex)
                                                                                            }
                                                                                            }>
                                                                                        <option value="">Select Country</option>
                                                                                        {countries.map((country, index) => (
                                                                                            <option key={index} value ={country.name}>{country.name}</option>
                                                                                        ))}
                                                                                    </select>
                                                                                </div>
                                                                            </>}


                                                                            <div className="save-remove-btn">
                                                                                <button onClick={() => {
                                                                                    saveRecipientData(index, propertiesIndex)
                                                                                }} className="gift-form-save-btn" type="submit">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                                         width="24"
                                                                                         height="24"
                                                                                         viewBox="0 0 24 24" fill="none">
                                                                                        <path
                                                                                            d="M3 5C3 3.89543 3.89543 3 5 3H9H15H16.5858C17.1162 3 17.6249 3.21071 18 3.58579L20.7071 6.29289C20.8946 6.48043 21 6.73478 21 7V19C21 20.1046 20.1046 21 19 21H15H9H5C3.89543 21 3 20.1046 3 19V5ZM9 19H15V13H9V19ZM17 19H19V7.41421L17 5.41421V7C17 8.10457 16.1046 9 15 9H9C7.89543 9 7 8.10457 7 7V5H5V19H7V13C7 11.8954 7.89543 11 9 11H15C16.1046 11 17 11.8954 17 13V19ZM9 5V7H15V5H9Z"
                                                                                            fill="currentColor"/>
                                                                                    </svg>
                                                                                    Save
                                                                                </button>
                                                                                {(productData?.properties?.length > 1) ? <button onClick={() => {
                                                                                    deleteRecipientData(propertiesIndex, index);
                                                                                }} className="gift-form-remove-btn" type="submit">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                                         width="24"
                                                                                         height="24"
                                                                                         viewBox="0 0 24 24" fill="none">
                                                                                        <path
                                                                                            d="M7 4C7 2.89543 7.89543 2 9 2H15C16.1046 2 17 2.89543 17 4V6H18.9897C18.9959 5.99994 19.0021 5.99994 19.0083 6H21C21.5523 6 22 6.44772 22 7C22 7.55228 21.5523 8 21 8H19.9311L19.0638 20.1425C18.989 21.1891 18.1182 22 17.0689 22H6.93112C5.88184 22 5.01096 21.1891 4.9362 20.1425L4.06888 8H3C2.44772 8 2 7.55228 2 7C2 6.44772 2.44772 6 3 6H4.99174C4.99795 5.99994 5.00414 5.99994 5.01032 6H7V4ZM9 6H15V4H9V6ZM6.07398 8L6.93112 20H17.0689L17.926 8H6.07398ZM10 10C10.5523 10 11 10.4477 11 11V17C11 17.5523 10.5523 18 10 18C9.44772 18 9 17.5523 9 17V11C9 10.4477 9.44772 10 10 10ZM14 10C14.5523 10 15 10.4477 15 11V17C15 17.5523 14.5523 18 14 18C13.4477 18 13 17.5523 13 17V11C13 10.4477 13.4477 10 14 10Z"
                                                                                            fill="currentColor"/>
                                                                                    </svg>
                                                                                    Remove
                                                                                </button>  : ''}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                            )
                                                        })}
                                                    </>}
                                                </div>
                                            </div>
                                        </>
                                    )
                                }

                            })}
                            </div>
                            <div  className="popup-footer">
                                <span style={{visibility : genrealGiftBoxCheckoutLoader && 'hidden'}}  onClick={() => {
                                    saveAllRecipientDetails(false)
                                }} className="no" id="saveLink">
                                    {!genrealGiftBoxLoader ? 'SAVE ALL RECIPIENT DETAILS' : ''}
                                    <div style={{display : genrealGiftBoxLoader && 'inline-block'}} className="loader" id="loader"/>
                                </span>
                                <span style={{visibility : genrealGiftBoxLoader && 'hidden'}} onClick={() => {
                                    saveAllRecipientDetails(true)
                                }}  className="yes">
                                    {!genrealGiftBoxCheckoutLoader ? 'CHECK OUT' : ''}
                                    <div style={{display : genrealGiftBoxCheckoutLoader && 'inline-block'}} className="loader" id="loader"/>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="overlaycolor ask-save-details-general-modal" style={{display: 'none'}}>
                    <div className="recipient-details">
                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                            <path
                                d="M25.001 8.33317C20.3986 8.33317 16.6676 12.0641 16.6676 16.6665C16.6676 21.2689 20.3986 24.9998 25.001 24.9998C29.6033 24.9998 33.3343 21.2689 33.3343 16.6665C33.3343 12.0641 29.6033 8.33317 25.001 8.33317ZM12.501 16.6665C12.501 9.76294 18.0974 4.1665 25.001 4.1665C31.9045 4.1665 37.501 9.76294 37.501 16.6665C37.501 23.5701 31.9045 29.1665 25.001 29.1665C18.0974 29.1665 12.501 23.5701 12.501 16.6665ZM16.6676 37.4998C13.2159 37.4998 10.4176 40.2981 10.4176 43.7498C10.4176 44.9004 9.4849 45.8332 8.33431 45.8332C7.18372 45.8332 6.25098 44.9004 6.25098 43.7498C6.25098 37.9969 10.9147 33.3332 16.6676 33.3332H33.3343C39.0873 33.3332 43.751 37.9969 43.751 43.7498C43.751 44.9004 42.8182 45.8332 41.6676 45.8332C40.517 45.8332 39.5843 44.9004 39.5843 43.7498C39.5843 40.2981 36.7861 37.4998 33.3343 37.4998H16.6676Z"
                                fill="#0091B9"/>
                        </svg>
                        <h2 className="popup-title">Do You Want to Save Recipient Details?</h2>
                        <div className="btns">
                            <span onClick={() => {
                                askSaveDataModalClose()
                            }}  className="yes">YES</span>
                            <span onClick={() => {
                                giftModalCloseFn()
                            }}  className="no">NO</span>
                        </div>
                    </div>
                </div>

            </>
            ,
            document.body
        )
    )
}
