import React, {useState} from 'react';
import {createPortal} from 'react-dom'


export default function LoveLogicCartDeleteRecipientData({productDataArr,deleteRecipientDataFromCartFn,delelteGiftItemLoader}) {

    const closeModal = () => {
        document.querySelector('.common-modal-ll-overlay').style.display = "none";
        document.querySelector('.love-logic-delete-recipient-data-modal').style.display = "none";
    }

    return (
        createPortal(<>
            <div className="common-modal-ll-overlay"/>
            <div id="recipientDetails" className="love-logic-delete-recipient-data-modal recipient-details popup" style={{display:"none"}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                    <path
                        d="M14.5827 8.33268C14.5827 6.0315 16.4482 4.16602 18.7493 4.16602H31.2493C33.5505 4.16602 35.416 6.0315 35.416 8.33268V12.4993H39.5612C39.5741 12.4992 39.587 12.4992 39.5999 12.4993H43.7493C44.8999 12.4993 45.8327 13.4321 45.8327 14.5827C45.8327 15.7333 44.8999 16.666 43.7493 16.666H41.5225L39.7156 41.9629C39.5598 44.1433 37.7455 45.8327 35.5595 45.8327H14.4392C12.2532 45.8327 10.4388 44.1433 10.2831 41.9629L8.47618 16.666H6.24935C5.09876 16.666 4.16602 15.7333 4.16602 14.5827C4.16602 13.4321 5.09876 12.4993 6.24935 12.4993H10.3988C10.4117 12.4992 10.4246 12.4992 10.4375 12.4993H14.5827V8.33268ZM18.7493 12.4993H31.2493V8.33268H18.7493V12.4993ZM12.6535 16.666L14.4392 41.666H35.5595L37.3452 16.666H12.6535ZM20.8327 20.8327C21.9833 20.8327 22.916 21.7654 22.916 22.916V35.416C22.916 36.5666 21.9833 37.4993 20.8327 37.4993C19.6821 37.4993 18.7493 36.5666 18.7493 35.416V22.916C18.7493 21.7654 19.6821 20.8327 20.8327 20.8327ZM29.166 20.8327C30.3166 20.8327 31.2493 21.7654 31.2493 22.916V35.416C31.2493 36.5666 30.3166 37.4993 29.166 37.4993C28.0154 37.4993 27.0827 36.5666 27.0827 35.416V22.916C27.0827 21.7654 28.0154 20.8327 29.166 20.8327Z"
                        fill="#0091B9"/>
                </svg>
                <h2 className="popup-title">Do You Want to Delete Recipient Details?</h2>
                <div  className="btns">
                    <span onClick={() => {
                        deleteRecipientDataFromCartFn(productDataArr)
                    }}  className="yes">
                        {!delelteGiftItemLoader ? 'YES' : ''}
                    <div style={{display : delelteGiftItemLoader && 'inline-block'}} className="loader" id="loader"/>
                    </span>
                    <span onClick={() => {
                        closeModal()
                    }}  className="no">NO</span>
                </div>
            </div>
        </>, document.body)
    )
}
