import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
// import './assets/css/newStyle.css';
import LoveLogicCart from "./pages/cart/LoveLogicCart";
// import LoveLogicProductPage from "./pages/product/LoveLogicProductPage";

try {
    console.log("love logic custom logic start....")
    if (/*window?.Shopify?.shop === 'love-and-logic.myshopify.com' &&*/ window?.Shopify?.theme?.id) {
        if (window?.__st?.pageurl?.includes('/cart')) {
            const script = document.querySelector('.love-logic-gift-po-hw');
            if (script) {
                let root = ReactDOM.createRoot(script);
                root.render(
                    <LoveLogicCart />
                );
            }
        }else if(window?.__st?.pageurl?.includes('/products/')){
            /*const script = document.querySelector('.love-logic-hw-product-load-data');
            if (script) {
                let root = ReactDOM.createRoot(script);
                root.render(
                    <LoveLogicProductPage />
                );
            }*/
        }
    }
}catch (error){
    console.error('error', error);
}


reportWebVitals();
