import React, {useEffect, useState} from 'react';
import {createPortal} from 'react-dom'
import {commonErrorFn, countries, findObjectWithProperties, formatMoney} from "../../helper/country";


export default function LoveLogicCartAddRecipientData({
                                                          cartData,
                                                          productDataArr,
                                                          RecipientGiftDetailsModalClose,
                                                          RecipientGiftDetailsSaveData,
                                                          editGiftRecipientDetailsFn,
                                                          openAddItemModalBtn,
                                                          editRecipientSaveBtn,
                                                          quantityHandleFnGiftEditItem,
                                                          editDetailsLoader
                                                      }) {
    const [editDetails, setDetails] = useState({});
    const [hasAddMoreItem, setHasAddMoreItem] = useState(false)
    const [addressShow, setAddressShow] = useState(false);
    const [errorShow, setErrorShow] = useState({});

    useEffect(() => {
        if (cartData.items?.length) {
            let currentvariantId = productDataArr.map(item => item.variant_id);
            const allMatch = cartData.items.every(item => currentvariantId.includes(item.variant_id));
            const has_add_more_item = cartData.items.some(item => !Object.keys(item?.properties)?.length);
            const bothTrueMoreItem = has_add_more_item && !allMatch;
            setHasAddMoreItem(bothTrueMoreItem);
        } else {
            setHasAddMoreItem(false)
        }

        if (productDataArr?.length) {
            let findIndex = findObjectWithProperties(productDataArr);
            let properties = {};
            if (findIndex > -1) {
                properties = productDataArr[findIndex].properties;
            } else {
                properties = productDataArr[0].properties;
            }
            setDetails(properties);
            let addressData = false;

            if ((properties?._country !== undefined) && (properties?._state !== undefined) && (properties?._city !== undefined) && (properties?._address !== undefined) && (properties?._zipcode !== undefined)) {
                addressData = true;
            } else {
                addressData = productDataArr.some(item => item.requires_shipping);
            }
            setAddressShow(addressData);
        } else {
            setDetails({})
            setAddressShow(false);
        }
        setErrorShow({});
    }, [productDataArr])


    const saveGiftRecipientData = (edit) => {
        let requires_shipping = productDataArr.some(item => item.requires_shipping)
        let giftProductAvailable = productDataArr.some(item => !item.requires_shipping);
        let formError = commonErrorFn(editDetails, requires_shipping,giftProductAvailable);
        if (!Object.keys(formError).length) {
            if (edit) {
                editGiftRecipientDetailsFn(editDetails)
            } else {
                RecipientGiftDetailsSaveData(editDetails)
            }
        } else {
            setErrorShow(formError);
        }
    }

    const askSaveDataModal = () => {
        document.querySelector('.ask-save-details-general-modal-edit-details').style.display = "block";
    }
    const askSaveDataModalClose = () => {
        document.querySelector('.ask-save-details-general-modal-edit-details').style.display = "none";
    }


    return (
        createPortal(<>
                {editDetailsLoader ? <div className="loader-parent-edit-data"><div className="genereal-gift-box-loader" /></div> : ''}
                <div id="giftModal" className="modal add-recipient-details-main edit-gift-deatails-modal">
                    <div className="modal-content">
                        <div className="force-overflow">

                            <div className="popup-header">
                                <h4>Edit Recipient Details</h4>
                                <span onClick={() => {
                                    askSaveDataModal()
                                }} className="close">&times;</span>
                            </div>
                            <div style={{opacity: (editDetailsLoader) && '0.2'}} className={`add-recipient-details ${editDetailsLoader ? 'for-mobile-class' : ''}`}>
                                <div className="hw--add-recipient-details">
                                    <div className="recipient-product">

                                        <div className="saved-recipients-header">
                                            <h2 className="saved-recipients-heading">Selected Gifts</h2>
                                            {hasAddMoreItem ?
                                                <button onClick={() => {
                                                    openAddItemModalBtn(editDetails?._edit)
                                                }} className="gift-form-remove-btn" type="submit"
                                                        id="remove-button">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                         viewBox="0 0 16 16"
                                                         fill="none">
                                                        <path
                                                            d="M8 0C8.55228 0 9 0.447715 9 1V7H15C15.5523 7 16 7.44772 16 8C16 8.55229 15.5523 9 15 9H9V15C9 15.5523 8.55228 16 8 16C7.44772 16 7 15.5523 7 15V9H1C0.447715 9 0 8.55229 0 8C0 7.44772 0.447715 7 1 7H7V1C7 0.447715 7.44772 0 8 0Z"
                                                            fill="currentColor"/>
                                                    </svg>
                                                    Add More Items
                                                </button> : null}
                                        </div>
                                        {productDataArr?.map((item,index) => {
                                            let cartItemsData = cartData?.items?.find((cartItems) => (cartItems?.variant_id === item.variant_id && !Object.keys(cartItems?.properties).length));
                                            let maxQuantity = 0;
                                            if(cartItemsData){
                                                maxQuantity = cartItemsData?.quantity;
                                            }
                                            return (
                                                <>
                                                    <div className="recipient-product-details-main">
                                                        <div className="recipient-product-details-img">
                                                            <img src={item?.image} alt={item?.product_title}
                                                                 height="115px"
                                                                 width="115px"/>
                                                        </div>
                                                        <div className="recipient-product-detail">
                                                            <a href={item?.url} target="_blank"
                                                               className="cart-item__details_title">{item?.product_title}</a>
                                                            <p className="cart-item__details_price"
                                                               style={{margin: "6px 0"}}>{formatMoney(item?.final_price)}</p>
                                                            {!item?.product_has_only_default_variant ?
                                                                <p className="cart-item__details_format">{item?.options_with_values?.[0]?.['name']}:{item?.options_with_values?.[0]?.['value']}</p>
                                                                : ''}
                                                            <div className="product-quantity-removed-total">
                                                                <div className="quantity">
                                                                    <button onClick={() => {
                                                                        quantityHandleFnGiftEditItem((parseInt(item?.quantity) - 1),item, productDataArr,index,'minus',cartItemsData)
                                                                    }} className="minus"
                                                                            aria-label="Decrease">-</button>
                                                                    <input disabled onChange={(event) => {
                                                                        let value = parseInt(event.target.value);
                                                                        if(value >= 0){
                                                                            if(item?.properties?._newAdd){
                                                                                value = (value <= item?.mainQuantity) ? value : item.mainQuantity;
                                                                            }
                                                                            if(!maxQuantity){
                                                                                value = (value <= item?.quantity) ? value : item.quantity;
                                                                            }
                                                                            quantityHandleFnGiftEditItem(value,item, productDataArr, index)
                                                                        }
                                                                    }} type="number" className="quantity-input-box"
                                                                           value={item?.quantity}
                                                                           min="1"/>
                                                                    <button disabled={!maxQuantity} onClick={() => {
                                                                        quantityHandleFnGiftEditItem((parseInt(item?.quantity) + 1),item, productDataArr, index,'plus',cartItemsData)
                                                                    }} className="plus" aria-label="Increase">+</button>
                                                                </div>
                                                                <div className="recipient-remove-button" onClick={() => {
                                                                    quantityHandleFnGiftEditItem(0,item, productDataArr, index,'minus',cartItemsData)
                                                                }}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                                         height="25"
                                                                         viewBox="0 0 24 25" fill="none">
                                                                        <path
                                                                            d="M7 4.5C7 3.39543 7.89543 2.5 9 2.5H15C16.1046 2.5 17 3.39543 17 4.5V6.5H18.9897C18.9959 6.49994 19.0021 6.49994 19.0083 6.5H21C21.5523 6.5 22 6.94772 22 7.5C22 8.05228 21.5523 8.5 21 8.5H19.9311L19.0638 20.6425C18.989 21.6891 18.1182 22.5 17.0689 22.5H6.93112C5.88184 22.5 5.01096 21.6891 4.9362 20.6425L4.06888 8.5H3C2.44772 8.5 2 8.05228 2 7.5C2 6.94772 2.44772 6.5 3 6.5H4.99174C4.99795 6.49994 5.00414 6.49994 5.01032 6.5H7V4.5ZM9 6.5H15V4.5H9V6.5ZM6.07398 8.5L6.93112 20.5H17.0689L17.926 8.5H6.07398ZM10 10.5C10.5523 10.5 11 10.9477 11 11.5V17.5C11 18.0523 10.5523 18.5 10 18.5C9.44772 18.5 9 18.0523 9 17.5V11.5C9 10.9477 9.44772 10.5 10 10.5ZM14 10.5C14.5523 10.5 15 10.9477 15 11.5V17.5C15 18.0523 14.5523 18.5 14 18.5C13.4477 18.5 13 18.0523 13 17.5V11.5C13 10.9477 13.4477 10.5 14 10.5Z"
                                                                            fill="currentColor"/>
                                                                    </svg>
                                                                </div>
                                                                <div className="recipient-product-details-total">
                                                                    <p className="cart-item__details_price"
                                                                       style={{margin: "6px 0"}}>{formatMoney(item?.final_line_price)}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })}

                                    </div>
                                </div>
                                <div className="recipient-personal-details">
                                    <div id="gift-form">
                                        <div className="recipient-personal-gift-form-one">
                                            <h2>Personal Details</h2>
                                            <div className="recipient-personal-detail">
                                                <input
                                                    style={{border: errorShow?._name && '1px solid red'}}
                                                    onChange={(event) => {
                                                        setDetails({...editDetails, _name: event.target.value})
                                                    }}
                                                    value={(editDetails?._name) ? editDetails?._name : ''}
                                                    type="text"
                                                    placeholder="Name"
                                                />
                                                <input
                                                    style={{border: errorShow?._email && '1px solid red'}}
                                                    onChange={(event) => {
                                                        setDetails({...editDetails, _email: event.target.value})
                                                    }}
                                                    value={(editDetails?._email) ? editDetails?._email : ''}
                                                    type="email"
                                                    placeholder="Email"
                                                />
                                                <input
                                                    style={{border: errorShow?._phone && '1px solid red'}}
                                                    onChange={(event) => {
                                                        setDetails({...editDetails, _phone: event.target.value})
                                                    }}
                                                    value={(editDetails?._phone) ? editDetails?._phone : ''}
                                                    type="text"
                                                    placeholder="Phone"
                                                />
                                            </div>
                                            <h2>Personalized Message (Optional)</h2>
                                            <div className="recipient-personal-message">
                                                <textarea
                                                    style={{border: errorShow?._note && '1px solid red'}}
                                                    onChange={(event) => {
                                                        setDetails({...editDetails, _note: event.target.value})
                                                    }}
                                                    value={(editDetails?._note) ? editDetails?._note : ''}
                                                    placeholder="Add your message here"
                                                />
                                            </div>
                                        </div>
                                        {addressShow ? <>
                                            <h2 className="gift-form-heading">Delivery Address</h2>

                                            <div className="address">
                                                <div className="hw-apartment-address">
                                                    <input
                                                        style={{border: errorShow?._address && '1px solid red'}}
                                                        onChange={(event) => {
                                                            setDetails({...editDetails, _address: event.target.value})
                                                        }}
                                                        value={(editDetails?._address) ? editDetails?._address : ''}
                                                        type="text"
                                                        className="address-input"
                                                        placeholder="Address"
                                                    />
                                                    <input
                                                        style={{border: errorShow?._address2 && '1px solid red'}}
                                                        onChange={(event) => {
                                                            setDetails({...editDetails, _address2: event.target.value})
                                                        }}
                                                        value={(editDetails?._address2) ? editDetails?._address2 : ''}
                                                        type="text"
                                                        className="apartment"
                                                        placeholder="Address 2"
                                                    />
                                                </div>
                                                <input
                                                    style={{border: errorShow?._city && '1px solid red'}}
                                                    onChange={(event) => {
                                                        setDetails({...editDetails, _city: event.target.value})
                                                    }}
                                                    value={(editDetails?._city) ? editDetails?._city : ''}
                                                    type="text"
                                                    className="city"
                                                    placeholder="city"
                                                />
                                                <select
                                                    style={{border: errorShow?._state && '1px solid red'}}
                                                    className="state"
                                                    value={(editDetails?._state) ? editDetails?._state : ''}
                                                    onChange={(event) => {
                                                        setDetails({...editDetails, _state: event.target.value})
                                                    }}>
                                                    <option value="">Select State</option>
                                                    {countries.find(country => country.name === editDetails?._country)?.states.map((state, index) => (
                                                        <option key={index} value={state}>{state}</option>
                                                    ))}
                                                </select>
                                                <input
                                                    style={{border: errorShow?._zipcode && '1px solid red'}}
                                                    onChange={(event) => {
                                                        setDetails({...editDetails, _zipcode: event.target.value})
                                                    }}
                                                    value={(editDetails?._zipcode) ? editDetails?._zipcode : ''}
                                                    type="text"
                                                    className="zip"
                                                    placeholder="zipcode"
                                                />
                                                <select
                                                    style={{border: errorShow?._country && '1px solid red'}}
                                                    className="country"
                                                    value={(editDetails?._country) ? editDetails?._country : ''}
                                                    onChange={(event) => {
                                                        setDetails({...editDetails, _country: event.target.value})
                                                    }
                                                    }>
                                                    <option value="">Select Country</option>
                                                    {countries.map((country, index) => (
                                                        <option key={index} value={country.name}>{country.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </> : null}

                                        <div className="recipient-product-detail-footer">
                                            <span onClick={() => {
                                                saveGiftRecipientData(editDetails?._edit)
                                            }} className="yes">{!editRecipientSaveBtn ? 'Save' : ''}
                                                <div style={{display : editRecipientSaveBtn && 'inline-block'}} className="loader"/>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="overlaycolor ask-save-details-general-modal-edit-details" style={{display: 'none'}}>
                    <div className="recipient-details">
                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                            <path
                                d="M25.001 8.33317C20.3986 8.33317 16.6676 12.0641 16.6676 16.6665C16.6676 21.2689 20.3986 24.9998 25.001 24.9998C29.6033 24.9998 33.3343 21.2689 33.3343 16.6665C33.3343 12.0641 29.6033 8.33317 25.001 8.33317ZM12.501 16.6665C12.501 9.76294 18.0974 4.1665 25.001 4.1665C31.9045 4.1665 37.501 9.76294 37.501 16.6665C37.501 23.5701 31.9045 29.1665 25.001 29.1665C18.0974 29.1665 12.501 23.5701 12.501 16.6665ZM16.6676 37.4998C13.2159 37.4998 10.4176 40.2981 10.4176 43.7498C10.4176 44.9004 9.4849 45.8332 8.33431 45.8332C7.18372 45.8332 6.25098 44.9004 6.25098 43.7498C6.25098 37.9969 10.9147 33.3332 16.6676 33.3332H33.3343C39.0873 33.3332 43.751 37.9969 43.751 43.7498C43.751 44.9004 42.8182 45.8332 41.6676 45.8332C40.517 45.8332 39.5843 44.9004 39.5843 43.7498C39.5843 40.2981 36.7861 37.4998 33.3343 37.4998H16.6676Z"
                                fill="#0091B9"/>
                        </svg>
                        <h2 className="popup-title">Do You Want to Save Recipient Details?</h2>
                        <div className="btns">
                            <span onClick={() => {
                                askSaveDataModalClose()
                            }}  className="yes">YES</span>
                            <span onClick={() => {
                                RecipientGiftDetailsModalClose()
                            }}  className="no">NO</span>
                        </div>
                    </div>
                </div>
            </>
            , document.body)
    )
}



