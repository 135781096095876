import React, {useEffect, useState} from 'react';
import LoveLogicCartAddRecipientData from "./LoveLogicCartAddRecipientData";
import LoveLogicCartShowGiftRecipientData from "./LoveLogicCartShowGiftRecipientData";
import LoveLogicCartDeleteRecipientData from "./LoveLogicCartDeleteRecipientData";
import LoveLogicCartGiftModal from "./LoveLogicCartGiftModal";
import LoveLogicCartGiftAddMoreItemsModal from "./LoveLogicCartGiftAddMoreItemsModal";
import LoveLogicCartPoOrder from "./LoveLogicCartPoOrder";
import LoveLogicCheckoutModal from "./LoveLogicCheckoutModal";
import axios from "axios";


const LoveLogicCart = () => {
    // const [productData, setProductData] = useState({});
    const [cartData, setCartData] = useState({});
    const [callUseEffectAgain, setCallEffectAgain] = useState(false);
    const [showGiftCheckBox, setShowGiftCheckBox] = useState(false);
    const [checkboxSelect, setCheckboxSelect] = useState(false);
    const [productDataArr, setProductDataArr] = useState([]);
    const [editGiftModal, setEditGiftModal] = useState(false);
    const [genrealGiftBoxLoader, setGenrealGiftBoxLoader] = useState(false);
    const [genrealGiftBoxCheckoutLoader, setGenrealGiftBoxCheckoutLoader] = useState(false);
    const [editRecipientSaveBtn, setEditRecipientSaveBtn] = useState(false);
    const [editDetailsLoader, setEditDetailsLoader] = useState(false);
    const [delelteGiftItemLoader, setDelelteGiftItemLoader] = useState(false);
    const [editGiftDetailsIndex, setEditGiftDetailsIndex] = useState('');
    const [cartHasItems, setCartHasItems] = useState(false);
    const [checkoutBtnModalLoader , setCheckoutBtnModalLoader] = useState(false);
    const [poOrderCheckBoxSelect, setPoOrderCheckBoxSelect] = useState({
        checkbox: false,
        poorderNumber: '',
        schoolName : '',
        base64File: '',
        poFile: ''
    });
    const [taxExemptCheckBoxSelect, setTaxExemptCheckBoxSelect] = useState({
        checkbox: false,
        taxExemptFile: '',
        base64File: ''
    });

    useEffect(() => {
        window.setProductDataArr = setProductDataArr;
    }, [productDataArr])

    // console.log(poOrderCheckBoxSelect, taxExemptCheckBoxSelect)


    useEffect(() => {
        checkoutBtn()
    },[])

    const checkoutBtn = () => {
        let checkoutBtnArr = document.querySelectorAll('[name="checkout"]');
        if(checkoutBtnArr?.length){
            checkoutBtnArr.forEach((checkout) => {
                checkout.setAttribute("onClick", "window.globalCheckoutFn(event,this)")
            });
        }

    }

    window.globalCheckoutFn = async (event,thisBtn) => {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();
        thisBtn.innerHTML = '';
        thisBtn.innerHTML = `<div style='display: inline-block' class="loader" id="loader"/>`;
        if(!poOrderCheckBoxSelect?.checkbox && !taxExemptCheckBoxSelect?.checkbox){
            if(cartData?.attributes?.['Po Order'] || cartData?.attributes?.['TAX Exemption']){
                let attributeData = {
                    'Po Order' : '',
                    'PO Number' : '',
                    'Organization Name' : '',
                    'PO File' : '',
                    'TAX Exemption' : '',
                    'TAX Exemption File' : ''
                };
                let config = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({attributes: attributeData})
                };
                await fetch('/cart/update.js', config);
            }
            thisBtn.innerHTML = '';
            thisBtn.innerHTML = 'Check out';
            window.location.href = '/checkout';
        }else{
            let modalShow = false;
            if(poOrderCheckBoxSelect?.checkbox){
                if(typeof poOrderCheckBoxSelect?.poFile == 'object'){
                    if(!poOrderCheckBoxSelect?.poFile?.name && poOrderCheckBoxSelect?.base64File === '' && poOrderCheckBoxSelect?.poorderNumber === ''){
                        modalShow = true;
                    }else if(poOrderCheckBoxSelect?.poorderNumber === ''){
                        modalShow = true;
                    }else if(poOrderCheckBoxSelect?.base64File === ''){
                        modalShow = true;
                    }
                }else if(poOrderCheckBoxSelect?.poFile === ''){
                    modalShow = true;
                }else if(poOrderCheckBoxSelect?.poorderNumber === ''){
                    modalShow = true;
                }
            }
            if(taxExemptCheckBoxSelect?.checkbox){
                if(typeof taxExemptCheckBoxSelect?.taxExemptFile == 'object'){
                    if(!taxExemptCheckBoxSelect?.taxExemptFile?.name && taxExemptCheckBoxSelect?.base64File === ''){
                        modalShow = true;
                    }
                }else if(taxExemptCheckBoxSelect?.taxExemptFile === '' ){
                    modalShow = true;
                }
            }
            if(modalShow){
                document.querySelector('.common-modal-ll-overlay').style.display = "block";
                document.querySelector('.without-details-cart-details-fill-checkout-modal').style.display = "block";
                thisBtn.innerHTML = '';
                thisBtn.innerHTML = 'Check out';
            }else{
                let attributeData = {};
                let poOrderBase64 = '';
                let taxExemptionBase64 = '';
                if(poOrderCheckBoxSelect?.checkbox){
                    if(typeof poOrderCheckBoxSelect?.poFile == 'object'){
                        poOrderBase64 = poOrderCheckBoxSelect?.base64File;
                    }
                }
                if(taxExemptCheckBoxSelect?.checkbox){
                    if(typeof taxExemptCheckBoxSelect?.taxExemptFile == 'object'){
                        taxExemptionBase64 = taxExemptCheckBoxSelect?.base64File
                    }
                }

                if(poOrderBase64 !== '' || taxExemptionBase64 !== ''){
                    let url = `${process.env.client_apiEndpoint}/upload-file`
                    let entryItemConfig = {
                        method: "post",
                        maxBodyLength: Infinity,
                        url: url,
                        data: {
                            poOrderBase64 : poOrderBase64,
                            taxExemptionBase64 : taxExemptionBase64
                        },
                    };
                    let fileResponseData = await axios.request(entryItemConfig);
                    if(fileResponseData?.data?.statusCode === 200){
                        fileResponseData = fileResponseData?.data?.data;
                        if(poOrderCheckBoxSelect?.checkbox){
                            if(fileResponseData?.poFile){
                                attributeData['Po Order'] = 'Yes';
                                attributeData['PO Number'] = poOrderCheckBoxSelect?.poorderNumber;
                                attributeData['Organization Name'] = poOrderCheckBoxSelect?.schoolName;
                                attributeData['PO File'] = fileResponseData?.poFile;
                            }
                        }else{
                            attributeData['Po Order'] = '';
                            attributeData['PO Number'] = '';
                            attributeData['Organization Name'] = '';
                            attributeData['PO File'] = '';
                        }

                        if(taxExemptCheckBoxSelect?.checkbox){
                            if(fileResponseData?.taxExemptionFile){
                                attributeData['TAX Exemption'] = 'Yes';
                                attributeData['TAX Exemption File'] = fileResponseData?.taxExemptionFile;
                            }
                        }else{
                            attributeData['TAX Exemption'] = '';
                            attributeData['TAX Exemption File'] = '';
                        }

                    }else{
                        alert('something went wrong')
                    }
                }else{
                    if(poOrderCheckBoxSelect?.checkbox){
                        attributeData['Po Order'] = 'Yes';
                        attributeData['PO Number'] = poOrderCheckBoxSelect?.poorderNumber;
                        attributeData['Organization Name'] = poOrderCheckBoxSelect?.schoolName;
                        attributeData['PO File'] = poOrderCheckBoxSelect?.poFile;
                    }else{
                        attributeData['Po Order'] = '';
                        attributeData['PO Number'] = '';
                        attributeData['Organization Name'] = '';
                        attributeData['PO File'] = '';
                    }

                    if(taxExemptCheckBoxSelect?.checkbox){
                        attributeData['TAX Exemption'] = 'Yes';
                        attributeData['TAX Exemption File'] = taxExemptCheckBoxSelect?.taxExemptFile;
                    }else{
                        attributeData['TAX Exemption'] = '';
                        attributeData['TAX Exemption File'] = '';
                    }
                }

                if(Object.keys(attributeData).length){
                    let config = {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({attributes: attributeData})
                    };
                    await fetch('/cart/update.js', config);
                }
                thisBtn.innerHTML = '';
                thisBtn.innerHTML = 'Check out';
                if(poOrderCheckBoxSelect?.checkbox || taxExemptCheckBoxSelect?.checkbox){
                    if(window.__st.cid){
                        window.location.href = '/checkout';
                    }else{
                        window.location.href = '/account/login?checkout_url=/checkout';
                    }
                }else{
                    window.location.href = '/checkout';
                }
            }
        }
    }


    const directCheckoutModalFn = async () => {
        setCheckoutBtnModalLoader(true);
        let attributeData = {
            'Po Order' : '',
            'PO Number' : '',
            'Organization Name' : '',
            'PO File' : '',
            'TAX Exemption' : '',
            'TAX Exemption File' : ''
        };
        let config = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({attributes: attributeData})
        };
        await fetch('/cart/update.js', config);
        window.location.href = '/checkout';
        setCheckoutBtnModalLoader(false);
    }


    useEffect(() => {
        fetchProductData();
    }, [callUseEffectAgain])

    useEffect(() => {
        if (cartData?.items?.length) {
            setCartHasItems(true)
            const hasGiftBox = cartData?.items?.some(item => !item?.properties?._gifting);
            if (!hasGiftBox) {
                document.querySelector('cart-items').style.display = "none";
            } else {
                document.querySelector('cart-items').style.display = "block";
            }
            setShowGiftCheckBox(hasGiftBox);
        } else {
            setCartHasItems(false)
            setShowGiftCheckBox(false);
        }
    }, [cartData])

    const originalFetch = window.fetch;

    window.fetch = new Proxy(originalFetch, {
        apply(target, that, args) {
            const [url, ...rest] = args;
            let ajaxUrl = '';

            if (url instanceof URL) {
                ajaxUrl = url.href;
            } else if (url instanceof Request) {
                ajaxUrl = url.url;
            } else {
                ajaxUrl = url || '';
            }

            if (ajaxUrl && ajaxUrl.includes('/cart/change')) {
                return originalFetch.apply(that, args)
                    .then(async (response) => {
                        const json = await response.clone().json();
                        processResponse(json); // Call processResponse without awaiting
                        return response;
                    })
                    .catch((error) => {
                        console.error('Fetch request failed or JSON parsing error:', error);
                        throw error; // Rethrow to propagate the error
                    });
            }

            // Default behavior: forward the fetch request if conditions are not met
            return originalFetch.apply(that, args);
        },
    });

    async function processResponse(responseJson) {
        try {
            // Simulate asynchronous processing with a delay
            await new Promise(resolve => setTimeout(resolve, 1000));

            // Example: process responseJson (replace with your logic)
            cartDataSettings(responseJson);
        } catch (error) {
            console.error('Error processing response:', error);
            throw error; // Rethrow to propagate the error
        }
    }


    const fetchCartData = async () => {
        let cartData = null;
        await fetch(`/cart.json`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                cartData = data
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
            });
        return cartData;
    }

    const cartDataSettings = async (cartData) => {
        setCartData(cartData)
        if(cartData?.attributes && Object.keys(cartData?.attributes).length){
            let attributes_data = cartData?.attributes;
            if(attributes_data?.['PO File'] && attributes_data?.['PO Number'] && attributes_data?.['Po Order']){
                setPoOrderCheckBoxSelect({
                    checkbox: true,
                    poorderNumber: attributes_data?.['PO Number'],
                    schoolName: attributes_data?.['Organization Name'],
                    base64File: '',
                    poFile: attributes_data?.['PO File']
                });
            }

            if(attributes_data?.['TAX Exemption'] && attributes_data?.['TAX Exemption File']){
                setTaxExemptCheckBoxSelect({
                    checkbox: true,
                    taxExemptFile: attributes_data?.['TAX Exemption File'],
                    base64File: ''
                })
            }
        }
    }

    const fetchProductData = async () => {
        const cartDataFromApi = await fetchCartData();
        cartDataSettings(cartDataFromApi)
    }

    const RecipientGiftDetailsModalClose = () => {
        document.querySelector('.ask-save-details-general-modal-edit-details').style.display = "none";
        document.querySelector('.edit-gift-deatails-modal').style.display = "none";
    }

    const cartRefreshFn = () => {
        fetch(`/cart?section_id=main-cart-items`)
            .then((response) => response.text())
            .then((responseText) => {
                const html = new DOMParser().parseFromString(responseText, 'text/html');
                const sourceQty = html.querySelector('cart-items');
                document.querySelector('cart-items').innerHTML = sourceQty.innerHTML;
                const addRecipientDataPerProduct = () => {
                    let cartProductAddRecipientBtn = document.querySelectorAll('.gift-add-recipient .add-recipient');
                    cartProductAddRecipientBtn.forEach((addRecipientBtn) => {
                        addRecipientBtn.addEventListener('click', function (event) {
                            event.preventDefault();
                            event.stopPropagation();
                            event.stopImmediatePropagation();
                            let product_data = this.getAttribute('data-item');
                            let product_line_item = this.getAttribute('data-index');
                            let productData = JSON.parse(product_data);
                            productData.lineItem = product_line_item;
                            window?.setProductDataArr([productData]);
                            document.querySelector('.edit-gift-deatails-modal').style.display = "block";
                        });
                    });
                }
                addRecipientDataPerProduct();
            })
            .catch((e) => {
                console.error(e);
            });
    }

    const dataFromRemoveProperties = (data) => {
        let newData = [];
        data.forEach(products => {
            let properties = {};
            properties['_gifting'] = true;
            properties['_name'] = products?.properties?._name;
            properties['_note'] = products?.properties?._note;
            properties['_phone'] = products?.properties?._phone;
            properties['_email'] = products?.properties?._email;
            if (products.requires_shipping) {
                properties['_country'] = products?.properties?._country;
                properties['_address'] = products?.properties?._address;
                properties['_address2'] = products?.properties?._address2;
                properties['_city'] = products?.properties?._city;
                properties['_state'] = products?.properties?._state;
                properties['_zipcode'] = products?.properties?._zipcode;
            } else {
            }
            newData.push({
                id: products?.id,
                quantity: products?.quantity,
                properties: properties
            })
        });
        return newData;
    }

    const RecipientGiftDetailsSaveData = async (details) => {
        details._gifting = true;
        let addData = [];
        for (let item of productDataArr) {
            let formDataChangeObject = {
                line: item?.lineItem,
                quantity: (item.quantity - 1),
            };
            let config = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(formDataChangeObject)
            }
            await fetch('/cart/change.js', config).then((response) => response.json()).then((response) => {
                console.log(response)
            }).catch((e) => {
                alert('something went wrong');
            });
            addData.push({
                id: item?.variant_id,
                quantity: 1,
                requires_shipping: item?.requires_shipping,
                properties: details
            });
        }
        if (addData?.length) {
            let addingData = dataFromRemoveProperties(addData);
            if (addingData.length) {
                let config = {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                    body: JSON.stringify({
                        items: addingData
                    })
                }
                await fetch('/cart/add.js', config).then((response) => response.json()).then((response) => {
                    cartRefreshFn();
                    fetchProductData();
                }).catch((e) => {
                    alert('something went wrong');
                });
            }
        }
        document.querySelector('.edit-gift-deatails-modal').style.display = "none";
    }

    const giftRecipientDeleteBtn = (productDataArray) => {
        setProductDataArr(productDataArray)
        document.querySelector('.love-logic-delete-recipient-data-modal').style.display = "block";
        document.querySelector('.common-modal-ll-overlay').style.display = "block";
    }


    const deleteRecipientDataFromCartFn = async (productDataArray) => {
        if (productDataArray.length) {
            setDelelteGiftItemLoader(true);
            let addNewData = [];
            for (let item of productDataArray) {
                let properties = {};
                if (item?.properties?._giftBox) {
                    properties = {
                        _giftBox: true
                    }
                }
                let formDataObject = {
                    id: item?.key,
                    quantity: 0,
                };
                let config = {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                    body: JSON.stringify(formDataObject)
                }
                await fetch('/cart/change.js', config).then((response) => response.json()).then((response) => {
                    addNewData.push({
                        id: item?.variant_id,
                        quantity: item?.quantity,
                        properties: properties
                    })
                    console.log(response)
                }).catch((e) => {
                    alert('something went wrong');
                });
            }
            if (addNewData?.length) {
                let config = {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                    body: JSON.stringify({
                        items: addNewData
                    })
                }
                await fetch('/cart/add.js', config).then((response) => response.json()).then((response) => {
                }).catch((e) => {
                    alert('something went wrong');
                });
            }
            cartRefreshFn();
            fetchProductData();
            setCallEffectAgain(true);
            setDelelteGiftItemLoader(false);
            document.querySelector('.common-modal-ll-overlay').style.display = "none";
            document.querySelector('.love-logic-delete-recipient-data-modal').style.display = "none";
        } else {
            document.querySelector('.common-modal-ll-overlay').style.display = "none";
            document.querySelector('.love-logic-delete-recipient-data-modal').style.display = "none";
        }
    }

    const editGiftRecipientDetailsBtn = (productData, productIndex) => {
        setEditGiftDetailsIndex(productIndex);
        let product_data = productData;
        product_data.forEach(item => {
            item.properties['_edit'] = true
        });
        setProductDataArr(product_data)
        document.querySelector('.edit-gift-deatails-modal').style.display = "block";
    }

    const removeUnusedProperties = (properties, requires_shipping) => {
        let newProperties = {...properties};
        if (!requires_shipping) {
            delete newProperties?._country;
            delete newProperties?._address;
            delete newProperties?._address2;
            delete newProperties?._city;
            delete newProperties?._state;
            delete newProperties?._zipcode;
        }
        delete newProperties?._newAdd;
        delete newProperties?._edit;
        return newProperties;
    }

    const editGiftRecipientDetailsFn = async (editDetailsData) => {
        setEditRecipientSaveBtn(true);
        setEditDetailsLoader(true);
        if (editDetailsData?._edit) {
            let addNewData = [];
            let updateQuantityData = {};
            for (let item of productDataArr) {
                let properties = removeUnusedProperties(editDetailsData, item?.requires_shipping);
                if (!item?.properties?._newAdd) {
                    let formDataChangeObject = {
                        line: item?.lineItem,
                        quantity: item?.quantity,
                        properties: properties,
                    };
                    let config = {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json'
                        },
                        body: JSON.stringify(formDataChangeObject)
                    }
                    await fetch('/cart/change.js', config).then((response) => response.json()).then((response) => {
                        console.log(response)
                    }).catch((e) => {
                        alert('something went wrong');
                    });
                } else {
                    updateQuantityData[item?.key] = ((item?.mainQuantity) - item?.quantity)
                    addNewData.push({
                        id: item?.variant_id,
                        quantity: item?.quantity,
                        properties: properties
                    })
                }
            }

            if (Object?.keys(updateQuantityData)?.length) {
                let config = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({updates: updateQuantityData})
                };
                await fetch('/cart/update.js', config);
            }

            if (addNewData?.length) {
                let config = {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                    body: JSON.stringify({
                        items: addNewData
                    })
                }
                await fetch('/cart/add.js', config).then((response) => response.json()).then((response) => {
                }).catch((e) => {
                    alert('something went wrong');
                });
            }
            cartRefreshFn();
            fetchProductData();
            document.querySelector('.edit-gift-deatails-modal').style.display = "none";
        } else {
            RecipientGiftDetailsSaveData(editDetailsData)
        }
        setEditDetailsLoader(false);
        setEditRecipientSaveBtn(false);
    }

    const giftModalOpenFn = () => {
        document.querySelector('.ll-cart-gift-modal').style.display = "block";
        setCheckboxSelect(true);
    }

    const giftModalCloseFn = () => {
        document.querySelector('.ask-save-details-general-modal').style.display = "none";
        document.querySelector('.ll-cart-gift-modal').style.display = "none";
        setCheckboxSelect(false);
        fetchProductData()
    }

    const saveAllRecipientData = async (product_data, checkout) => {
        if (checkout) {
            setGenrealGiftBoxCheckoutLoader(true);
        } else {
            setGenrealGiftBoxLoader(true);
        }
        let originalProductData = {};
        let giftItemData = [];
        if (product_data?.length) {
            product_data.map((products) => {
                if (products.checkbox) {
                    if (products.properties.length) {
                        /*Original Product Data Start*/
                        let totalGiftQty = products.properties?.reduce((acc, obj) => {
                            // Convert _quantity to a number if it's a string
                            let quantity = typeof obj._quantity === 'string' ? parseInt(obj._quantity) : obj._quantity;
                            return acc + quantity;
                        }, 0);
                        /*let originalData = {
                            // key : products?.itemData?.key,
                             [products?.itemData?.key] : totaloriginalProductQty,

                        };*/
                        originalProductData[products?.itemData?.key] = (parseInt(products?.itemData?.quantity) - parseInt(totalGiftQty));
                        /*Original Product Data end*/

                        /*Gift Product Data Start*/
                        products.properties.map((item) => {
                            let formDataObject = {
                                id: products?.itemData?.variant_id,
                                quantity: parseInt(item?._quantity),
                            };
                            delete item._quantity;
                            item._gifting = true;
                            formDataObject.properties = item;
                            giftItemData.push(formDataObject);
                        })
                        /*Gift Product Data end*/
                    }
                }
            })
        }
        if (Object.keys(originalProductData)?.length) {
            let config = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({updates: originalProductData})
            };
            await fetch('/cart/update.js', config).then(response => response.json()).then(updatedCart => {
                console.log("Updated cart:", updatedCart);
            }).catch(error => {
                alert('something went wrong');
            });
            cartRefreshFn();
            document.querySelector('.ll-cart-gift-modal').style.display = "none";
            setCheckboxSelect(false);
        }
        if (giftItemData?.length) {
            let config = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    items: giftItemData
                })
            }
            await fetch('/cart/add.js', config).then((response) => response.json()).then((response) => {
                cartRefreshFn();
                fetchProductData();
            }).catch((e) => {
                alert('something went wrong');
            });
        }
        document.querySelector('.ll-cart-gift-modal').style.display = "none";
        setCheckboxSelect(false);
        setGenrealGiftBoxCheckoutLoader(false);
        setGenrealGiftBoxLoader(false);
        if (checkout) {
            window.location.href = '/checkout';
        }
    }

    const openAddItemModalBtn = (editModal) => {
        if (editModal) {
            setEditGiftModal(true);
        } else {
            setEditGiftModal(false);
        }
        document.querySelector('.ll-add-more-items-modal').style.display = "flex";
    }

    const addAGiftAdditionalItemAddFn = async (data) => {
        if (data?.length) {
            setProductDataArr([...productDataArr, ...data])
            document.querySelector('.ll-add-more-items-modal').style.display = "none";
        }
    }

    const quantityHandleFnGiftEditItem = async (quantity, item, productDataArr, index, plusMinus, cartItemsData) => {
        if (!item?.properties?._newAdd) {
            let closeEditPopup = false;
            let findEndKey = `${item?.properties?._name}_${item?.properties?._email}`;
            if (productDataArr?.length === 1 && !quantity) {
                closeEditPopup = true;
            }
            setEditDetailsLoader(true);
            let formDataChangeObject = {
                line: item?.lineItem,
                quantity: quantity,
            };
            let config = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(formDataChangeObject)
            }
            await fetch('/cart/change.js', config).then((response) => response.json()).then((response) => {
                if (closeEditPopup) {
                    document.querySelector('.edit-gift-deatails-modal').style.display = "none";
                }/* else {
                    setEditableDataRefreshFn(response)
                }*/
            }).catch((e) => {
                alert('something went wrong');
            });

            if (plusMinus === 'minus') {
                await fetch('/cart/add.js', {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                    body: JSON.stringify({
                        items: [{
                            id: item?.variant_id,
                            quantity: parseInt(item?.quantity) - parseInt(quantity),
                        }]
                    })
                });
            } else {
                let updateData = {
                    [cartItemsData?.key]: parseInt(cartItemsData?.quantity) - 1
                }
                await fetch('/cart/update.js', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({updates: updateData})
                });
            }
            if(!closeEditPopup){
                const cart_data = await fetchCartData();
                setEditableDataRefreshFn(cart_data,findEndKey)
            }
            cartRefreshFn();
            fetchProductData();
            setEditDetailsLoader(false);
        } else {
            let newProductDataArr = productDataArr;
            if (!quantity) {
                newProductDataArr.splice(index, 1);
                setProductDataArr([...newProductDataArr])
            } else {
                newProductDataArr[index].quantity = (quantity <= item?.mainQuantity) ? quantity : item.mainQuantity;
                setProductDataArr([...newProductDataArr])
            }
        }
    }

    const setEditableDataRefreshFn = (cartData,findEndKey) => {
        if (cartData?.items?.length) {
            const hasGiftingItem = cartData.items.some(item => item?.properties?._gifting);
            if (hasGiftingItem) {
                let product_data = cartData.items;
                const giftedData = product_data.map((obj, index) => {
                    if (obj.properties && obj?.properties?._gifting) {
                        return {
                            ...obj, lineItem: index + 1
                        }
                    }
                });
                let filteredObjects = giftedData.filter(item => item !== null && item !== undefined);
                const groupedObjects = filteredObjects.reduce((groups, obj) => {
                    const key = `${obj.properties._name}_${obj.properties._email}`;
                    if (!groups[key]) {
                        groups[key] = [];
                    }
                    groups[key].push(obj);
                    return groups;
                }, {});
                const groupedArray = Object.keys(groupedObjects).map(key => groupedObjects[key]);
                let keyIndex = Object.keys(groupedObjects).indexOf(findEndKey);
                let finalIndex = editGiftDetailsIndex;
                if(keyIndex > -1){
                    finalIndex = keyIndex;
                }
                editGiftRecipientDetailsBtn(groupedArray[finalIndex], finalIndex)
            }
        }
    }

    const handleFilechange = (event, type) => {
        let files = event?.target?.files[0];
        if ((files?.size <= 5000000)) {
            const reader = new FileReader();
            reader.readAsDataURL(files);
            reader.onload = () => {
                const base64Image = reader.result;
                if (type === 'po') {
                    setPoOrderCheckBoxSelect({
                        ...poOrderCheckBoxSelect,
                        poFile: files,
                        base64File: base64Image
                    });
                } else {
                    setTaxExemptCheckBoxSelect({
                        ...taxExemptCheckBoxSelect,
                        taxExemptFile: files,
                        base64File: base64Image
                    });
                }
            }
        } else {
            if (files?.size >= 5000000) {
                alert('file size is too large, it must be less than 5 MB');
            }
        }
    }

    const deleteFileFn = (type) => {
        if (type === 'po') {
            setPoOrderCheckBoxSelect({
                ...poOrderCheckBoxSelect,
                poFile: '',
                base64File: ''
            });
        } else {
            setTaxExemptCheckBoxSelect({
                ...taxExemptCheckBoxSelect,
                taxExemptFile: '',
                base64File: ''
            });
        }
    }

    return (
        <>
            {showGiftCheckBox && <>
                <div className="main-row">
                    <div className="col">
                        <div className="gift-box" onClick={() => {
                            giftModalOpenFn()
                        }}>
                            <div className="gift-heading" id="gift-options">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21"
                                     fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M1.66748 2.81853C1.66748 4.0031 2.71912 5.49676 4.97174 6.42352H1.45833C0.654167 6.42352 0 7.07769 0 7.88186V9.96519C0 10.7694 0.654167 11.4235 1.45833 11.4235H1.66669V19.1319C1.66669 19.936 2.32085 20.5902 3.12502 20.5902H9.99511L10 20.5902L10.0049 20.5902H16.875C17.6792 20.5902 18.3334 19.936 18.3334 19.1319V11.4235H18.5417C19.3458 11.4235 20 10.7694 20 9.96519V7.88186C20 7.07769 19.3458 6.42352 18.5417 6.42352H15.0292C17.2817 5.49675 18.3333 4.00312 18.3333 2.81857C18.3333 1.77357 17.5325 0.553568 15.2742 0.552734C12.4568 0.552734 10.8204 2.55085 10.0003 4.45369C9.18007 2.55109 7.54388 0.553528 4.72748 0.553528C2.46915 0.553528 1.66748 1.77353 1.66748 2.81853ZM10.625 19.3402H16.875C16.9303 19.3402 16.9833 19.3182 17.0223 19.2792C17.0614 19.2401 17.0834 19.1871 17.0834 19.1319V11.4235H10.625V19.3402ZM10.625 10.1735H18.5417C18.5969 10.1735 18.6499 10.1516 18.689 10.1125C18.7281 10.0734 18.75 10.0204 18.75 9.96519V7.88186C18.75 7.8266 18.7281 7.77361 18.689 7.73454C18.6499 7.69547 18.5969 7.67352 18.5417 7.67352H10.625V10.1735ZM9.375 7.67352V10.1735H1.45833C1.34333 10.1735 1.25 10.0802 1.25 9.96519V7.88186C1.25 7.8266 1.27195 7.77361 1.31102 7.73454C1.35009 7.69547 1.40308 7.67352 1.45833 7.67352H9.375ZM9.375 11.4235V19.3402H3.12502C3.01002 19.3402 2.91669 19.2469 2.91669 19.1319V11.4235H9.375ZM10.78 6.02524C11.1558 4.64107 12.2892 2.30353 14.7268 2.30353C15.4768 2.30353 15.8892 2.42491 16.0936 2.62538C16.3283 2.84232 16.6666 3.31563 16.6666 4.06857C16.6666 5.03551 15.3763 6.42352 12.5 6.42352H10.78ZM9.22 6.02524H7.5C4.62365 6.02524 3.33331 4.63722 3.33331 3.66924C3.33331 2.9163 3.67156 2.44299 3.90626 2.22604C4.1107 2.02556 4.52312 1.90418 5.27314 1.90418C7.71075 1.90418 8.84418 4.24172 9.22 6.02524Z"
                                          fill="#ED1D24"/>
                                </svg>
                                <h4>Gift Options</h4>
                            </div>
                            <p>You can select items from your cart to gift to others. *Schools - use Gift Options to assign digital purchases to staff members.</p>
                        </div>
                    </div>
                </div>
            </>}
            {cartHasItems ? <LoveLogicCartPoOrder poOrderCheckBoxSelect={poOrderCheckBoxSelect} taxExemptCheckBoxSelect={taxExemptCheckBoxSelect} handleFilechange={handleFilechange} deleteFileFn={deleteFileFn} setTaxExemptCheckBoxSelect={setTaxExemptCheckBoxSelect} setPoOrderCheckBoxSelect={setPoOrderCheckBoxSelect}/> : null }
            <LoveLogicCartGiftModal cartData={cartData} giftModalCloseFn={giftModalCloseFn}
                                    saveAllRecipientData={saveAllRecipientData}
                                    genrealGiftBoxLoader={genrealGiftBoxLoader}
                                    genrealGiftBoxCheckoutLoader={genrealGiftBoxCheckoutLoader}/>
            <LoveLogicCartGiftAddMoreItemsModal productDataArr={productDataArr} cartData={cartData}
                                                addAGiftAdditionalItemAddFn={addAGiftAdditionalItemAddFn}
                                                editGiftModal={editGiftModal}/>
            <LoveLogicCartAddRecipientData cartData={cartData} productDataArr={productDataArr}
                                           RecipientGiftDetailsModalClose={RecipientGiftDetailsModalClose}
                                           RecipientGiftDetailsSaveData={RecipientGiftDetailsSaveData}
                                           editGiftRecipientDetailsFn={editGiftRecipientDetailsFn}
                                           openAddItemModalBtn={openAddItemModalBtn}
                                           editRecipientSaveBtn={editRecipientSaveBtn}
                                           quantityHandleFnGiftEditItem={quantityHandleFnGiftEditItem}
                                           editDetailsLoader={editDetailsLoader}
            />
            <LoveLogicCartShowGiftRecipientData cartData={cartData} giftRecipientDeleteBtn={giftRecipientDeleteBtn}
                                                editGiftRecipientDetailsBtn={editGiftRecipientDetailsBtn}/>
            <LoveLogicCartDeleteRecipientData productDataArr={productDataArr}
                                              deleteRecipientDataFromCartFn={deleteRecipientDataFromCartFn}
                                              delelteGiftItemLoader={delelteGiftItemLoader}/>
            <LoveLogicCheckoutModal directCheckoutModalFn={directCheckoutModalFn} checkoutBtnModalLoader={checkoutBtnModalLoader}  />
        </>
    )
}

export default LoveLogicCart
